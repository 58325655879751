import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import queryString from 'query-string';
import {
  Form, Input, Button, Checkbox, Modal, message, Alert
} from 'antd';

import {
  MailOutlined,
  LockOutlined,
  GoogleOutlined,
  FacebookOutlined,
  TwitterOutlined
} from '@ant-design/icons';

import { withFirebase } from '../Firebase';
import { createUser } from '../Functions';

import styles from '../../index.css';
import * as ROUTES from "../../constants/routes";

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';
const ERROR_MSG_ACCOUNT_EXISTS = `
  Email address is associated with another account: This social-login uses an email address that already has an account with us. 
  The existing account could be a password-login account or a different social-login account.  
  If you wish to use this social-login in the future, log in with the existing account first, and then you can link this social-login
  on your personal account page.
`;

const INITIAL_STATE = {
  error: null,
  loading: false,
  alert: false,
};



class NormalLoginForm extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };


  }

  handleCancel = () => {
    this.setState({ alert: false });
    this.props.form.resetFields();
    // console.log("reset fields");
    this.props.handleCancel();
  };



  onFinish = async (values) => {

    // console.log(values);
    // const form = this.props.form;
    // console.log(form);
    const { email, password, remember } = values;

    await this.setState({ loading: true });

    // this.props.form.validateFields((err, values) => {
    //   if (!err) {


    if (remember) {

      this.props.firebase
        .doSignInWithEmailAndPassword(email, password)
        // .then(() => )
        // // .then(() => setTimeout(() => { console.log("wait") }, 2500))
        .then(() => {
          this.handleCancel();
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ error, loading: false });
          console.log('update error', error);
          console.log(error.code);
          message.error(error.message, 2);
        });


    } else {

      this.props.firebase
        .doSetSessionPersistence()
        .then(() => this.props.firebase
          .doSignInWithEmailAndPassword(email, password))
        .then(() => {
          this.handleCancel();
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ error, loading: false });
          console.log('update error', error);
          message.error(error.message, 2);
        });

    }

    // } else {
    //   this.setState({ loading: false });
    //   message.error('Please check your entries for error', 1);
    //   console.log("here's the validation error:", err);
    // }
    // });

  }

  onSubmitGoogle = async event => {
    const params = this.props.location.search;
    const refer = params && queryString.parse(params).refer;
    const remember = this.props.form.getFieldValue('remember');

    event.preventDefault();

    await this.setState({ loading: true });

    if (remember) {
      this.props.firebase
        .doSignInWithGoogle()
        // .then(socialAuthUser => {
        //   // Create a user in your Firebase Realtime Database too
        //   return this.props.firebase
        //     .user(socialAuthUser.user.uid)
        //     .set({
        //       username: socialAuthUser.user.displayName,
        //       email: socialAuthUser.user.email,
        //     });
        // })
        .then(socialAuthUser => createUser(socialAuthUser, refer, socialAuthUser.user.displayName, socialAuthUser.user.email))
        .then(() => {
          this.handleCancel();
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ error, loading: false });
          console.log('update error', error);
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            this.setState({ alert: true });
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
            console.log(error.message);
          } else { message.error(error.message, 2); }
        });
    } else {
      this.props.firebase
        .doSetSessionPersistence()
        .then(() => this.props.firebase
          .doSignInWithGoogle())
        .then(socialAuthUser => createUser(socialAuthUser, refer, socialAuthUser.user.displayName, socialAuthUser.user.email))
        .then(() => {
          this.handleCancel();
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ error, loading: false });
          console.log('update error', error);
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            this.setState({ alert: true });
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
            console.log(error.message);
          } else { message.error(error.message, 2); }
        });

    }
  };


  onSubmitFacebook = async event => {
    const params = this.props.location.search;
    const refer = params && queryString.parse(params).refer;
    const remember = this.props.form.getFieldValue('remember');

    event.preventDefault();

    await this.setState({ loading: true });

    if (remember) {
      this.props.firebase
        .doSignInWithFacebook()
        .then(socialAuthUser => createUser(socialAuthUser, refer, socialAuthUser.additionalUserInfo.profile.name, socialAuthUser.additionalUserInfo.profile.email))
        .then(() => {
          this.handleCancel();
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ error, loading: false });
          console.log('update error', error);
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            this.setState({ alert: true });
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
            console.log(error.message);
          } else { message.error(error.message, 2); }
        });

    } else {
      this.props.firebase
        .doSetSessionPersistence()
        .then(() => this.props.firebase
          .doSignInWithFacebook())
        .then(socialAuthUser => createUser(socialAuthUser, refer, socialAuthUser.additionalUserInfo.profile.name, socialAuthUser.additionalUserInfo.profile.email))
        .then(() => {
          this.handleCancel();
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ error, loading: false });
          console.log('update error', error);
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            this.setState({ alert: true });
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
            console.log(error.message);
          } else { message.error(error.message, 2); }
        });

    }


  };

  onSubmitTwitter = async event => {
    const params = this.props.location.search;
    const refer = params && queryString.parse(params).refer;
    const remember = this.props.form.getFieldValue('remember');

    event.preventDefault();

    await this.setState({ loading: true });

    if (remember) {
      this.props.firebase
        .doSignInWithTwitter()
        // .then(socialAuthUser => {
        //   // Create a user in your Firebase Realtime Database too
        //   return this.props.firebase.user(socialAuthUser.user.uid).set({
        //     username: socialAuthUser.additionalUserInfo.profile.name,
        //     email: socialAuthUser.additionalUserInfo.profile.email,
        //     // roles: [],
        //   });
        // })
        .then(socialAuthUser => createUser(socialAuthUser, refer, socialAuthUser.additionalUserInfo.profile.name, socialAuthUser.additionalUserInfo.profile.email))
        .then(() => {
          this.handleCancel()
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ error, loading: false });
          console.log('update error', error);
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            this.setState({ alert: true });
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
            console.log(error.message);
          } else { message.error(error.message, 2); }
        });

    } else {
      this.props.firebase
        .doSetSessionPersistence()
        .then(() => this.props.firebase
          .doSignInWithTwitter())
        .then(socialAuthUser => createUser(socialAuthUser, refer, socialAuthUser.additionalUserInfo.profile.name, socialAuthUser.additionalUserInfo.profile.email))
        .then(() => {
          this.handleCancel()
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          this.setState({ error, loading: false });
          console.log('update error', error);
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            this.setState({ alert: true });
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
            console.log(error.message);
          } else { message.error(error.message, 2); }
        });

    }

  };


  handleSignup = event => {
    event.preventDefault();
    this.handleCancel();
    this.props.handleSignup();
  }

  handlePwforget = event => {
    event.preventDefault();
    this.handleCancel();
    this.props.handlePwforget();
  }

  render() {
    // const { getFieldDecorator } = this.props.form;

    const PossibleAlert = () => this.state.alert ?
      <Alert
        message="Duplicate Account"
        description={ERROR_MSG_ACCOUNT_EXISTS}
        type="error"
        closable={true}
        onClose={this.onClose}
        banner={true}
      /> : <div></div>

    //   getFieldDecorator gives a field its id. e.g. 'email', then id='normal_login_email'

    return (


      <Modal
        visible={this.props.visible}
        title="Sign in"
        onCancel={this.handleCancel}
        footer={null}
        closable={true}
        maskClosable={false}
        width={440}
      >
        <PossibleAlert />
        {/* <Form onSubmit={this.onSubmit} className={styles["login-form"]} > */}
        <Form onFinish={this.onFinish} name="normal_login" className={styles["login-form"]} form={this.props.form} initialValues={{ remember: true }}>
          {/* style={{maxWidth: '300px',margin: 'auto'}} */}
          <Form.Item name="email"
            rules={[{ type: 'email', message: 'Not a valid email address' },
            { required: true, message: ' ' }]}
            validateTrigger={['onBlur']}
          >
            <Input allowClear name="email" prefix={<MailOutlined style={{ opacity: 0.3 }} />}
              placeholder="Email address" autoComplete="email" />
          </Form.Item>
          <Form.Item name="password"
            rules={[{ required: true, message: ' ' }]}
          >
            <Input.Password name="password" prefix={<LockOutlined style={{ opacity: 0.3 }} />}
              placeholder="Password" autoComplete="current-password" allowClear />
          </Form.Item>
          <Form.Item name="remember"
            valuePropName='checked'
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item>
            <Link className="login-form-forgot" to="#" onClick={this.handlePwforget}>Forgot Password</Link>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
              Log in
            </Button>

            Don't have an account?&nbsp;
            <Link to="#" onClick={this.handleSignup}>&nbsp;Sign up</Link>
          </Form.Item>
          <span style={{ margin: "auto" }}> Log in with&nbsp;&nbsp;
            <GoogleOutlined style={{ fontSize: "18px" }} onClick={this.onSubmitGoogle} />&nbsp;&nbsp;
            <FacebookOutlined style={{ fontSize: "18px" }} theme="filled" onClick={this.onSubmitFacebook} />&nbsp;&nbsp;
            <TwitterOutlined style={{ fontSize: "18px" }} onClick={this.onSubmitTwitter} />
          </span>

        </Form>
      </Modal>

    );
  }
};

// const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

const WrappedNormalLoginForm = (props) => {
  const [form] = Form.useForm();
  return <NormalLoginForm form={form} {...props} />
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(WrappedNormalLoginForm);




export default SignInForm;
