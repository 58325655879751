import React, { Component, Fragment } from 'react';

import {
    Row, List, Button, Tabs, message, Rate, Col
} from 'antd';

import {
    PlusOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons';

import { Link } from 'react-router-dom';
import moment from "moment";

import { makeCancelable } from '../Hooks';
import YoutubeEmbed from './youtube-embed';
import RowForm from './row-form';
import PdfUploader from './pdf-uploader';

const { TabPane } = Tabs;


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {

            error: null,
            loading: false,
            mstart: null,
            sstart: null,
            mend: null,
            send: null,
            segments: [],
            selectedKey: null,
            repeat: 0,
            scoreUrl: null,
            rating: this.props.location && this.props.location.state && this.props.location.state.rating,

        };

        // const { piece, video } = this.props.match.params;
        ({ piece: this.piece, video: this.video } = this.props.match.params);
        ({ video_url: this.video_url } = (this.props.location && this.props.location.state) || null);

    }


    pendingPromises = [];


    componentWillUnmount = () =>
        this.pendingPromises.map(p => p.cancel());

    appendPendingPromise = promise =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = promise =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);



    componentDidMount = () => {

        // const { piece, video } = this.props.location && this.props.location.search;

        const query = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}/${this.piece}/${this.video}/segments`);

        const wrappedPromise = makeCancelable(query.once("value"));
        this.appendPendingPromise(wrappedPromise);
        this.setState({ loading: true });

        wrappedPromise.promise
            .then(snapshot => snapshot.val())
            .then(rawdata => {
                if (Object.keys(rawdata).length > 0) {

                    Object.entries(rawdata).forEach(([k, t], i) => {
                        if (!t.name && !t.shortname) {
                            this.handleDelete(k);
                        }
                    });

                    const tableData = Object.entries(rawdata).map(([k, t], i) => ({
                        key: parseInt(k),
                        name: t.name,
                        range: t.range,

                    }));

                    this.setState({ segments: tableData, loading: false, });
                    this.removePendingPromise(wrappedPromise);

                }
            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error, loading: false });
                    this.removePendingPromise(wrappedPromise);
                }
            });

    }



    // removeFromDb(uid, label, refname) {
    //     const ref = this.props.firebase[refname];
    //     return ref(uid).child(label)
    //         .remove()
    //         .catch(error => {
    //             this.setState({ error })
    //         });
    // }



    handleDelete = (itemkey) => {

        const dbref = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}/${this.piece}/${this.video}/segments/${itemkey}`);
        const wrappedPromise = makeCancelable(dbref.remove());
        this.appendPendingPromise(wrappedPromise);

        this.setState({
            dbLoading: true,
            segments: this.state.segments.filter(d => d.key !== itemkey)
        });

        wrappedPromise.promise
            .then(() => this.removePendingPromise(wrappedPromise))
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error });
                    this.removePendingPromise(wrappedPromise);
                } else {
                    console.log("deletion is cancelled");
                }
            });
        this.setState({ dbLoading: false });
    }



    addSegmentRow = (e) => {
        e.preventDefault();
        this.setState((state) => ({
            segments: [...state.segments, { key: state.segments.length }]
        }));
    }



    onClickRadio = (key, val) => {
        const sstart = (val.range0 && val.range0.seconds()) || 0;
        const mstart = (val.range0 && val.range0.minutes()) || 0;
        const send = (val.range1 && val.range1.seconds()) || 0;
        const mend = (val.range1 && val.range1.minutes()) || 0;

        this.setState({ sstart, mstart, send, mend, selectedKey: key, repeat: this.state.repeat + 1 });

    }


    onTabChange = (tab) => {

        if (tab === '2' && !this.state.scoreUrl) {
            const query = this.props.firebase.db.ref(`userpianoscores/${this.props.authUser.uid}/${this.piece}/fileUrl`);

            const wrappedPromise = makeCancelable(query.once("value"));
            this.appendPendingPromise(wrappedPromise);
            this.setState({ loading: true });

            wrappedPromise.promise
                .then(snapshot => snapshot.val())
                .then(rawdata => {
                    if (Object.keys(rawdata).length > 0) {

                        // console.log(rawdata);
                        const storageRef = this.props.firebase.storage.ref();

                        storageRef
                            .child(rawdata)
                            .getDownloadURL()
                            .then(fr => {
                                this.setState({
                                    scoreUrl: fr,
                                    loading: false
                                });
                            });

                        this.removePendingPromise(wrappedPromise);

                    }
                })
                .catch(errorInfo => {
                    if (!errorInfo.isCanceled) {
                        this.setState({ error: errorInfo.error, loading: false });
                        this.removePendingPromise(wrappedPromise);
                    }
                });
        }
    }

    afterFileUpload = (obj) => {

        const submitTime = Math.round(moment().format("X"));
        const submitObj = { ...obj, submitTime };
        const storageRef = this.props.firebase.storage.ref();

        storageRef
            .child(obj.fileUrl)
            .getDownloadURL()
            .then(fr => {
                this.setState({
                    scoreUrl: fr,
                });
            });

        this.setState({ loading: true, error: null, justUploaded: true });

        const dbref = this.props.firebase.db.ref(
            `userpianoscores/${this.props.authUser.uid}/${this.piece}`
        );

        dbref
            .update(submitObj)
            .then(() => {
                this.setState({
                    loading: false,
                });
                message.success("Score saved");
            })
            .catch((error) => {
                this.setState({ error, loading: false });
                console.log("update error", error);
                message.error(error.message, 2);
            });


    };


    onRatingChange = (val) => {

        this.setState({ rating: val });

        const submitTime = Math.round(moment().format('X'));

        const dbref = this.props.firebase.db.ref(`userpianovideos/${this.props.authUser.uid}/${this.piece}`);

        dbref.update({ rating: val, submitTime })
            .catch(error => {
                console.log('Rating update error', error);
                message.error(error.message, 2);
            }).then(() => {
                message.success("Rating saved");
            });

    }


    render() {

        const {
            // loading,
            mstart,
            sstart,
            mend,
            send,
            segments,
            selectedKey,
            repeat
        } = this.state;




        const footerButton = <Row type="flex" justify="center" style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }}>
            <Button style={{ width: "70%" }} type="dashed" onClick={this.addSegmentRow}><PlusOutlined />Add</Button>
        </Row>;


        // const { video_url, rating } = this.props.location && this.props.location.state;


        return (

            <Fragment>

                <Row type="flex" justify="start" style={{ marginTop: 0, marginLeft: 40, marginRight: 40, marginBottom: 0 }}>
                    <Col span={4}>
                        <Link
                            to={{
                                pathname: `/pianovideos`,
                            }}
                            style={{ margin: 20, fontSize: 20 }}
                        >
                            <ArrowLeftOutlined />
                        </Link>
                    </Col>
                    <Col span={4}>
                        <Rate allowClear={true} value={this.state.rating} onChange={val => this.onRatingChange(val, this.piece)} />
                    </Col>
                </Row>

                <Tabs defaultActiveKey="1" onChange={this.onTabChange} tabPosition="top" style={{ marginLeft: 20 }}>
                    <TabPane tab="Video Segments" key="1">

                        <Row type="flex" justify="center" style={{ marginTop: 20, marginLeft: 80, marginRight: 80, marginBottom: 40 }}>
                            {this.video_url ? <YoutubeEmbed src={this.video_url} mstart={mstart} sstart={sstart} mend={mend} send={send} repeat={repeat} /> : <div></div>}
                        </Row>

                        <Row type="flex" justify="center" style={{ marginTop: 20, marginLeft: 80, marginRight: 80, marginBottom: 40 }}>
                            <List
                                style={{ width: "100%" }}
                                header={<h3>Segments</h3>}
                                footer={footerButton}
                                bordered
                                dataSource={segments}
                                renderItem={item => (

                                    <RowForm {...this.props}
                                        item={item}
                                        selected={item.key === selectedKey}
                                        onClickRadio={this.onClickRadio}
                                        pieceId={this.piece}
                                        videoId={this.video}
                                        onClickDelete={this.handleDelete}
                                    />


                                )}
                            />
                        </Row>
                    </TabPane>
                    <TabPane tab="Score" key="2">
                        <Row type="flex" justify="center" style={{ marginTop: 20, marginLeft: 80, marginRight: 80, marginBottom: 40 }}>
                            <PdfUploader
                                firebase={this.props.firebase}
                                authUser={this.props.authUser}
                                afterFileUpload={this.afterFileUpload}
                            // existingData={tableData} 
                            />

                        </Row>
                        <Row type="flex" justify="center" style={{ marginTop: 20, marginLeft: 80, marginRight: 80, marginBottom: 40 }}>
                            <a style={{ fontSize: 20 }} href={this.state.scoreUrl}>Sheet</a>
                        </Row>
                    </TabPane>
                </Tabs>






            </Fragment >

        );
    }
}




export default App;

