import React, { useState } from "react";

import {
    Button,
    Row,
    Popover,
    List,
    Input,
    message
} from "antd";

import {
    SearchOutlined,
    EditTwoTone,
    DeleteTwoTone,
    CheckOutlined,
    CloseOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";


import * as ROUTES from "../../constants/routes";


const PopInput = (props) => {

    const [visible, setVisible] = useState(false);

    const onPressEnter = e => {
        e.preventDefault();
        setVisible(false);
        props.onPressEnter(e);
    }

    const handleVisibleChange = v => {
        setVisible(v);
    }

    return (
        <Popover
            content={<Input style={{ width: 300 }} defaultValue={props.defaultValue} onPressEnter={onPressEnter} />}
            // title="Title"
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
        >
            <Button type="text" style={{ width: "20%" }} icon={<EditTwoTone />} />
        </Popover>
    )

}


const App = (props) => {


    const onEditLink = (e, piece, video) => {

        e.preventDefault();

        const val = e.target.value;


        const dbref = props.firebase.db.ref(`userpianovideos/${props.authUser.uid}/${piece}/videos/${video}`);

        dbref.set(val)
            .catch(error => {
                console.log('Link update error', error);
                message.error(error.message, 2);
            }).then(() => {
                message.success("Link saved");
            });

        props.handleSave(piece, video, val);

    }



    return (


        <List
            // style={{ width: "100%" }}
            header={null}
            footer={null}
            dataSource={props.value}
            renderItem={item => (
                <Row>
                    <Link
                        style={{ width: "80%" }}
                        to={{
                            pathname: ROUTES.PLAYVIDEO + `/${props.piece}/${item.id}`,  //define this in the form of /:piece/:video in the Router for the whole App
                            state: {
                                // pieceId: props.item.key,
                                // videoId: item.id,
                                video_url: item.link,
                                rating: props.rating
                            },
                            // query: { piece: props.item.key, video: item.id }
                        }} >
                        <span >Part {item.order + 1}</span>
                    </Link>
                    {/* <Popover
                        content={<Input style={{ width: 300 }} defaultValue={item.link} onPressEnter={val => onEditLink(val, props.piece, item.id)} />}
                        // title="Title"
                        trigger="click"
                    // visible={this.state.visible}
                    // onVisibleChange={this.handleVisibleChange}
                    >
                        <Button type="text" style={{ width: "20%" }} icon={<EditTwoTone />} />
                    </Popover> */}

                    <PopInput defaultValue={item.link} onPressEnter={e => onEditLink(e, props.piece, item.id)} />

                </Row>
            )}
        />




    )

};


export default App;
