export const BORROW = "/borrow";
export const READ = "/read";
export const BOOKSHELF = "/bookshelf";
export const QUIZ = "/quiz";
export const LANDING = "/bookshelf";
export const PROFILE = "/profile";
export const SETTINGS = "/settings";
export const CRED = "/cred";
export const HOME = "/";
export const PLAYVIDEO = "/play";
export const PIANOVIDEOS = "/pianovideos";
export const TEXTCOMPARE = '/textcompare';
export const TERMS = 'terms';
