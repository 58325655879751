import React, { useEffect, useRef } from "react";

import {
    Button,
    Row,
    Col,
    Radio,
    Input,
    Form,
    message,
    TimePicker
} from "antd";

import {
    MinusOutlined,
} from "@ant-design/icons";

import moment from 'moment';


const App = (props) => {

    const [form] = Form.useForm();

    const toPicker = useRef();
    const fromPicker = useRef();

    const onSaving = (cleanvalues, key) => {

        // e.preventDefault();

        // const values = form.getFieldsValue();
        // const cleanvalues = Object.entries(values).reduce((a, [k, v]) => (v === undefined ? a : (a[k] = v, a)), {});

        const keyobj = {
            name: cleanvalues.name.trim() || null,
            range: [(cleanvalues.range0 && Math.round(cleanvalues.range0.format('X'))) || null,
            (cleanvalues.range1 && Math.round(cleanvalues.range1.format('X'))) || null],
        };


        if (!keyobj.name && !keyobj.range) {
            return
        }

        const submitObj = { [key.toString()]: keyobj };

        if (JSON.stringify({ key, ...keyobj }) === JSON.stringify(props.item)) {
            message.warn('no change detected');

        } else {

            const dbref = props.firebase.db.ref(`${props.dbrefname}/${props.authUser.uid}/${props.pieceId}/${props.videoId}/segments`);
            const submitTime = Math.round(moment().format('X'));

            dbref.update(submitObj)
                .catch(error => {
                    console.log('update error', error);
                    message.error(error.message, 2);
                }).then(() => {
                    // setLoading(false);
                    // props.onFinish({ [props.pushkey]: submitObj });
                    message.success("segment saved");
                });

            const dbref2 = props.firebase.db.ref(`userpianovideos/${props.authUser.uid}/${props.pieceId}`);

            dbref2.update({ submitTime })
                .catch(error => {
                    message.error(error.message, 2);
                });

        }



    }


    const onClickRadio = (e, key) => {
        e.preventDefault();


        const values = form.getFieldsValue();
        const cleanvalues = Object.entries(values).reduce((a, [k, v]) => (v === undefined ? a : (a[k] = v, a)), {});

        onSaving(cleanvalues, key);

        props.onClickRadio(key, cleanvalues);
    }


    // // try to save onBlur
    const onBlur = (e, tp, key) => {

        console.log('target value', e.target.value);
        // const momentv = moment(e.target.value, 'mm:ss');


        // if (tp === "from" && fromPicker.current) {
        //     console.log(form.getFieldsValue("range0"));
        //     // form.setFieldsValue({ range0: momentv });
        // } else if (tp === "to" && toPicker.current) {
        //     // form.setFieldsValue({ range1: momentv });
        //     console.log(form.getFieldValue("range1"));
        // }

        // const values = form.getFieldsValue();
        // const cleanvalues = Object.entries(values).reduce((a, [k, v]) => (v === undefined ? a : (a[k] = v, a)), {});

        // onSaving(cleanvalues, key);
    }

    const onToChange = (value, key) => {

        // console.log('value is', value);

        props.onSelect && props.onSelect(value);
        props.onChange &&
            props.onChange(value, value.format(props.format?.toString()));

        const values = form.getFieldsValue();
        const cleanvalues = Object.entries(values).reduce((a, [k, v]) => (v === undefined ? a : (a[k] = v, a)), {});

        onSaving(cleanvalues, key);

    }

    const onClickDelete = (e) => {
        e.preventDefault();
        // console.log(props.item.key);
        props.onClickDelete(props.item.key);
        // const time
    }


    // onSelect={value => {
    //     props.onSelect && props.onSelect(value)
    //     props.onChange &&
    //       props.onChange(value, value.format(props.format?.toString()))
    //   }}

    useEffect(() => {

        if (Object.keys(props.item).length > 0) {

            let isSubscribed = true;

            if (isSubscribed) {

                form.setFieldsValue({

                    name: props.item.name || '',
                    range0: props.item.range && props.item.range.length && moment.unix(props.item.range[0]),
                    range1: props.item.range && props.item.range.length && moment.unix(props.item.range[1]),
                });

            }

            return () => (isSubscribed = false);
        } else {
            // setLoadedForm(true);
        }

    }, [props.item]);


    return (
        <Form name="form" form={form}
            layout="horizontal"
            style={{ width: "100%", height: 30, margin: 10 }}
        >
            <Row type="flex" justify="center" style={{ height: 30, marginLeft: 20, marginRight: 10 }} gutter={22}>
                <Col span={1} style={{ height: 30 }}>
                    <Radio
                        style={{ width: "100%" }}
                        onClick={e => onClickRadio(e, props.item.key)}
                        checked={props.selected}
                    />
                </Col >
                <Col span={13} style={{ height: 30 }}>
                    <Form.Item
                        name="name"
                        label="name"
                        style={{ width: "100%" }}
                    ><Input />
                    </Form.Item>
                </Col >
                <Col span={5} style={{ height: 30 }}>
                    <Form.Item
                        name="range0"
                        label="range"
                        style={{ width: "100%" }}
                    ><TimePicker
                            format="mm:ss"
                            ref={fromPicker}
                            onBlur={e => onBlur(e, 'from', props.item.key)}
                            placeholder="from"
                            //try to save on first enter; no need to reconfirm
                            // onSelect doesn't seem to fire on Enter
                            // but onChange does. onChange means selected value changes regardless because of enter or mouse click
                            onChange={value => {
                                // console.log(value);
                                props.onSelect && props.onSelect(value);
                                props.onChange &&
                                    props.onChange(value, value.format(props.format?.toString()));
                                toPicker.current && toPicker.current.focus();
                            }}
                        /></Form.Item>
                </Col>

                <Col span={3} style={{ height: 30 }}>
                    <Form.Item
                        name="range1"
                        style={{ width: "100%" }}
                    ><TimePicker format="mm:ss"
                        ref={toPicker}
                        onBlur={e => onBlur(e, 'to', props.item.key)}
                        placeholder="to"
                        //try to save on first enter; no need to reconfirm
                        // doesn't seem to fire on Enter
                        // onSelect={value => onToSelect(value, props.item.key)}
                        onChange={value => onToChange(value, props.item.key)}
                        /></Form.Item>
                </Col>

                <Col span={1} style={{ height: 30 }}>
                    <Button shape="circle" icon={<MinusOutlined />} onClick={onClickDelete} />
                </Col>
                <Col span={1} style={{ height: 30 }}>
                </Col>

            </Row>
        </Form>


    )

};


export default App;
