import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";

import HomeModule from "./page";

function Editing(props) {
  return (
    <React.Fragment>
      <HomeModule {...props} refname="userp" />
    </React.Fragment>
  );
}

const EditingBase = compose(withRouter, withFirebase)(Editing);

const EditingPage = (props) => {
  return (
    <AuthUserContext.Consumer>
      {(authUser) => <EditingBase authUser={authUser} />}
    </AuthUserContext.Consumer>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
  // withQuiz,
)(EditingPage);
