import React from 'react';


const App = (props) => {

    const endsrc = `?start=${props.mstart * 60 + props.sstart}&end=${props.mend * 60 + props.send}&rel=0&showinfo=0`;

    return (<div className="video-responsive" key={props.repeat}>
        <iframe
            width="853"
            height="480"
            src={props.src + endsrc}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>)
};


export default App;