import React, { Component } from "react";
import { Upload, message } from "antd";
import {
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import ePub from "epubjs";
// import moment from "moment";

const { Dragger } = Upload;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      cover: "",
      error: null,
      itemKey: null,
      // tableData: [],
    };
  }

  handleChange = (info) => {
    // console.log(info);
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (someinfo) => {
        this.setState({
          loading: false,
        });
      });

      this.props.afterFileUpload({
        ...info.file.originFileObj.bookMetadata,
        fileUrl: info.file.originFileObj.newurl,
      });
    }
  };

  beforeUpload = (file) => {
    //beforeUpload only prevent upload behavior when return false or reject promise
    //async await doensn't work here
    //if you use a promise you have to return reject or resolve; return true or false doesn't work

    // You can remove this validation if you want
    const isLimitSize = file.size / 1024 / 1024 < 50;
    if (!isLimitSize) {
      message.error("File must smaller than 50MB!");
    }

    // const existingTitles = this.props.existingData.map((e) => e.title);
    const isType = file.type === "application/epub+zip";
    if (!isType) {
      message.error("You can only upload epub file!");
      return false;
    }

    const epub = ePub(file);
    return epub.loaded.metadata.then((metadata) => {
      //check duplicates
      const existingBooks = this.props.existingData.filter(
        (e) => e.title === metadata.title && e.creator === metadata.creator
      );
      // const isDuplicate = existingTitles.includes(metadata.title);
      const isDuplicate = existingBooks.length > 0;
      file.bookMetadata = metadata;
      if (isDuplicate) {
        message.error(
          `Duplicate with existing book: ${metadata.title} by ${existingBooks[0].creator}`,
          10
        );
      }
      if (isType && isLimitSize && !isDuplicate) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    });
  };

  customUpload = async ({ onError, onSuccess, file }) => {
    const storage = this.props.firebase.storage;
    // You can use custom metadata for storing additional app specific data for each file,
    // but we highly recommend using a database (such as the Firebase Realtime Database)
    // to store and synchronize this type of data.
    // const metadata = {
    //     customMetadata: {
    //       'location': 'Yosemite, CA, USA',
    //       'activity': 'Hiking'
    //     }
    //   }
    // console.log("custom upload file is:", file);
    // const epub = ePub(file);

    // epub &&
    //   epub.loaded.metadata.then((metadata) => {
    //     // console.log(metadata);
    //     file.bookMetadata = metadata;
    //   });

    const oldFileName = file.name.split(".").slice(0, -1).join(".");
    const storageRef = await storage.ref();
    const newFileName =
      oldFileName + Math.round(Math.random() * 1000).toString(); //a unique name for the image
    // const newFileName = oldFileName;
    const url = `books/${newFileName}.epub`;
    file.newurl = url;
    const imgFile = storageRef.child(url);

    try {
      const image = await imgFile.put(file);
      onSuccess(null, image);
    } catch (e) {
      onError(e);
    }
  };

  render() {
    const { loading } = this.state;
    //   const uploadButton = (
    //   <div>
    //     {loading ? <LoadingOutlined /> : <PlusOutlined />}
    //     <div className="ant-upload-text">Upload</div>
    //   </div>
    //   );
    return (
      <Dragger
        name="epub"
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
        customRequest={this.customUpload}
        multiple={true}
        //   fileList={this.state.fileList}
        style={{ minWidth: "400px" }}
      >
        {/* <Button icon={loading ? <LoadingOutlined /> : <UploadOutlined />}>
          Upload
        </Button> */}
        <p className="ant-upload-drag-icon">
          {loading ? <LoadingOutlined /> : <UploadOutlined />}
        </p>
        <p className="ant-upload-text">Drag files or click here to upload</p>
      </Dragger>
    );
  }
}

export default App;
