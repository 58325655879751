import React, { Component, Fragment, useMemo } from 'react';

import {
    Row, List, Button, Tabs, message, Card, Col, Input, Avatar, Typography, InputNumber, Skeleton, Spin
} from 'antd';

import ePub from "epubjs";

import ReactDiffViewer from 'react-diff-viewer';

import { Link } from 'react-router-dom';
import moment from "moment";

import { makeCancelable } from '../Hooks';


const { TabPane } = Tabs;
const { TextArea } = Input;
const { Meta } = List.Item;


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {

            error: null,
            loading: true,
            segments: [],
            selectedKey: null,
            repeat: 0,
            scoreUrl: null,
            recall: '',
            activeTab: "0",
            paragraphs: [],
            currentText: null,
            page: 0,
            title: null,
            startingChapter: null,
            chunkSize: null,
            promptLength: null,
            histories: [],
            pageOfTexts: [],


        };

        this.pageOfTexts = [];

        this.overallRef = React.createRef();


    }


    pendingPromises = [];

    checkIfMounted() {
        return this.overallRef.current != null;
    }


    componentWillUnmount = () =>
        this.pendingPromises.map(p => p.cancel());

    appendPendingPromise = promise =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = promise =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);

    divideText = (parags, limit) => {

        const paralength = parags.length;

        const result = parags.reduce((resultArray, item, index) => {

            const candidate = resultArray.length > 0 ? resultArray.slice(-1)[0] + '\n' + item : item;

            let newArray;

            // join if joining doesn't cause a very large chunk, and the index doesn't indicate it's the last item anyway
            if (candidate.length > limit && index < paralength - 1) {
                newArray = [...resultArray, item];
                // console.log('length too long, results array is', newArray);
            } else {
                newArray = [...resultArray.slice(0, -1), candidate]

            }

            // console.log('results array is', newArray);

            return newArray
        }, []);

        // console.log('end of chapter at', parags.slice(-3,));

        return result
    }



    componentDidMount = () => {



        var parser = new DOMParser();

        const fileUrl = this.props.history.location.state && this.props.history.location.state.url;
        const title = this.props.history.location.state && this.props.history.location.state.title;
        // console.log(fileUrl, title);

        const storageRef = this.props.firebase.storage.ref();


        const query = this.props.firebase.db.ref(`userrecites/${this.props.authUser.uid}/${title.trim().toLowerCase()}`);

        const wrappedPromise = makeCancelable(query.once("value"));
        this.appendPendingPromise(wrappedPromise);

        wrappedPromise.promise
            .then(snapshot => snapshot.val())
            .then(rawdata => {

                if (rawdata && Object.keys(rawdata).length > 0) {

                    const attempts = rawdata.attempts;
                    const { chunkSize, promptLength, startingChapter } = rawdata;

                    // console.log('the three vars are', chunkSize, promptLength, startingChapter, 'and', startingChapter >= 0 ? startingChapter : this.state.startingChapter);

                    const tableData = attempts && Object.entries(attempts).map(([k, t], i) => ({
                        key: k,
                        order: i,
                        originalText: t.originalText,
                        recall: t.recall,
                        createTime: t.createTime || null,
                        prompt: t.prompt,
                    }));

                    // this.setState({
                    //     histories: tableData || [],
                    //     // loading: false,
                    //     chunkSize: chunkSize || 1200,
                    //     promptLength: promptLength || 20,
                    //     startingChapter: startingChapter >= 0 ? startingChapter : 1,
                    // });
                    this.removePendingPromise(wrappedPromise);

                    return {
                        histories: tableData || [],
                        chunkSize: chunkSize || 1200,
                        promptLength: promptLength || 20,
                        startingChapter: startingChapter >= 0 ? startingChapter : 1,
                    }

                } else {

                    return {
                        histories: [],
                        chunkSize: 1200,
                        promptLength: 20,
                        startingChapter: 1,
                    }

                }
            })
            .then(params => {

                fileUrl && storageRef
                    .child(fileUrl)
                    .getDownloadURL()
                    .then((fr) => {
                        const book = ePub(fr);

                        // console.log('book is', book);
                        let promis = [];
                        let ps;

                        function waitThenSay(item) {
                            //wrap the function to be run, so that it's not triggered, when pushed to the list
                            return item.load(book.load.bind(book));
                        }

                        book.loaded.spine.then(spine => {
                            spine.each(item => {
                                promis.push(() => waitThenSay(item));
                            });

                            Promise.all(promis.map(p => p())).then(contents => {
                                const inners = contents.map(c => c.innerHTML);

                                // var htmlDoc = parser.parseFromString(txt, 'text/html');
                                ps = inners.map(s => [...parser.parseFromString(s, 'text/html').getElementsByTagName("p")].map(p => p.textContent));
                                // const chunks = ps.map(p => this.divideText(p, this.state.chunkSize)).flat();


                                if (this.checkIfMounted()) {
                                    //only after we update paragraphs would we update the params, triggering componentdidUpdate
                                    this.setState({ title, paragraphs: ps }, () => {
                                        if (this.checkIfMounted()) {
                                            this.setState({ ...params });
                                        }
                                    });
                                }


                            });
                        });

                    });

            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error, loading: false });
                    this.removePendingPromise(wrappedPromise);
                }
            });











    }



    // removeFromDb(uid, label, refname) {
    //     const ref = this.props.firebase[refname];
    //     return ref(uid).child(label)
    //         .remove()
    //         .catch(error => {
    //             this.setState({ error })
    //         });
    // }



    handleDelete = (itemkey) => {

        const dbref = this.props.firebase.db.ref(`userrecites/${this.props.authUser.uid}/${this.state.title.trim().toLowerCase()}/attempts/${itemkey}`);
        const wrappedPromise = makeCancelable(dbref.remove());
        this.appendPendingPromise(wrappedPromise);

        this.setState({
            dbLoading: true,
            histories: this.state.histories.filter(d => d.key !== itemkey)
        });

        wrappedPromise.promise
            .then(() => this.removePendingPromise(wrappedPromise))
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error });
                    this.removePendingPromise(wrappedPromise);
                } else {
                    console.log("deletion is cancelled");
                }
            });

        this.setState({ dbLoading: false });
    }



    // addSegmentRow = (e) => {
    //     e.preventDefault();
    //     this.setState((state) => ({
    //         segments: [...state.segments, { key: state.segments.length }]
    //     }));
    // }



    // onClickRadio = (key, val) => {
    //     const sstart = (val.range0 && val.range0.seconds()) || 0;
    //     const mstart = (val.range0 && val.range0.minutes()) || 0;
    //     const send = (val.range1 && val.range1.seconds()) || 0;
    //     const mend = (val.range1 && val.range1.minutes()) || 0;

    //     this.setState({ sstart, mstart, send, mend, selectedKey: key, repeat: this.state.repeat + 1 });

    // }


    onTabChange = (tab) => {

        this.setState({ activeTab: tab });


    }



    onRecallChange = e => {
        e.preventDefault();
        this.setState({ recall: e.target.value });
    }

    onRecallSubmit = e => {
        e.preventDefault();
        this.setState({ activeTab: "4" });
    }

    onRecallSave = e => {
        e.preventDefault();
        this.handleSave(this.state.title.trim() || 'no title');
        this.setState({ activeTab: "3" });
    }

    handleSave = (bookname) => {
        const dbref = this.props.firebase.db.ref(`userrecites/${this.props.authUser.uid}/${bookname.trim().toLowerCase()}/attempts`);
        const submitTime = Math.round(moment().format('X'));

        const newdata = {
            createTime: submitTime,
            recall: this.state.recall,
            prompt: this.state.currentText && this.state.currentText.prompt,
            originalText: this.state.currentText && this.state.currentText.originalText
        };

        const wrappedPromise = makeCancelable(dbref.push(newdata));

        this.setState({
            dbLoading: true,
        });

        wrappedPromise.promise
            .then(snap => {
                this.setState({
                    // pushkey: snap.key,
                    histories: [{ ...newdata, key: snap.key, order: this.state.histories.length }, ...this.state.histories,],
                    dbLoading: false,
                    // tableData: [...this.state.tableData, { ...newdata, id: snap.key }],
                    // visible: true,
                });
                this.removePendingPromise(wrappedPromise);
                message.success("Created a new memory");
            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error, dbLoading: false });
                    console.log('push error', errorInfo.error);
                    this.removePendingPromise(wrappedPromise);
                    message.error(errorInfo.message, 2);
                } else {
                    console.log("text saving is cancelled");
                }
            });

    }


    onSelectText = item => {
        console.log(item.prompt);
        this.setState({ currentText: item, activeTab: "1" });
    }


    saveBookInfo = (name, val) => {

        const dbref = this.props.firebase.db.ref(`userrecites/${this.props.authUser.uid}/${this.state.title.trim().toLowerCase()}`);
        const newdata = { [name]: val };
        const wrappedPromise = makeCancelable(dbref.update(newdata));

        this.setState({
            dbLoading: true,
        });

        wrappedPromise.promise
            .then(() => {
                this.removePendingPromise(wrappedPromise);
                message.success("value saved");
            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error, dbLoading: false });
                    console.log('push error', errorInfo.error);
                    this.removePendingPromise(wrappedPromise);
                    message.error(errorInfo.message, 2);
                } else {
                    console.log("write is cancelled");
                }
            });

    }


    onChangeInputNumber = (e, which) => {
        e.preventDefault();
        const v = parseInt(e.target.value);

        if (which === 'promptLength') {
            this.setState({ loading: true, promptLength: v });
            this.saveBookInfo('promptLength', v);
        } else if (which === 'chunkSize') {
            this.setState({ loading: true, chunkSize: v });
            this.saveBookInfo('chunkSize', v);
        } else {
            this.setState({ loading: true, startingChapter: v });
            this.saveBookInfo('startingChapter', v);
        }

    }


    componentDidUpdate(prevProps, prevState) {

        //expensive computation of the pages of texts. With react functional component you can use useMemo. 
        //with class component, you can use ComponentDidUpdate

        if (prevState.chunkSize !== this.state.chunkSize || prevState.promptLength !== this.state.promptLength || prevState.startingChapter !== this.state.startingChapter) {

            if (this.checkIfMounted()) {
                this.setState({ loading: true }, () => updating());
            }

            const updating = () => {
                const cardsPerBatch = 200;
                // you need a positive look behind, aka, the part of string that comes before the match should follow the pattern of
                // promptLengh of any char, followed by no char you'd like to split on -- aka you split on the first instance
                // capturing groups don't work coz you are going to use it to split

                // here I'm splitting on the first comma or period that's after promptLength chars (because promptLength can't be too short)
                const newre = new RegExp(`(?<=^[\\s\\S]{${this.state.promptLength}}[^,.，。?？、]*)[,.，。?？、]\\s?`, "g");
                // (?<=^[\s\S]{10,})[,.，。]\s?
                // (?<=^[\s\S]{10}[^,.，。]*)[,.，。]\s?
                //(?<=^[\\s\\S]{${this.state.promptLength},})[,.，。]\\s?

                const texts = this.state.paragraphs.slice(this.state.startingChapter,).map(p => this.divideText(p, this.state.chunkSize)).flat();

                // const pageOfTexts = texts.slice(cardsPerBatch * this.state.page, cardsPerBatch * (this.state.page + 1)).map((t, i) => {
                const pageOfTexts = texts.map((t, i) => {
                    // const [prompt, originalText] = t.split(/(?<=^[\s\S]{20}[^,.，。]+)[,.，。]\s?/);
                    // if you want to use a variable in the regex, you'd need to use a new RegExp, like above outside the function

                    const [prompt, originalText] = t.split(newre);
                    // console.log(t.split(newre));
                    return { id: i, originalText, prompt };
                });

                if (this.checkIfMounted()) {
                    this.setState({ pageOfTexts, loading: false });
                }
            }

        }

    }



    handleTryAgain = item => {
        // console.log(item);
        const { originalText, prompt } = item;

        this.setState({ currentText: { originalText, prompt }, activeTab: "2" });
    }



    render() {

        const {
            recall,
            activeTab,
            currentText,
            title,
            promptLength,
            chunkSize,
            histories,
            startingChapter
        } = this.state;



        return (

            <div ref={this.overallRef}>

                < Tabs activeKey={activeTab} onChange={this.onTabChange} tabPosition="top" style={{ marginLeft: 20 }}>
                    <TabPane tab="Select Text" key="0">



                        <Skeleton loading={this.state.loading} active paragraph={{ rows: 12, width: 1000 }}>
                            <Row type="flex" justify="center" style={{ marginTop: 40, marginLeft: 24, marginRight: 24, marginBottom: 40, fontSize: 16 }}>
                                {/* you don't have to update the state of the inputs at all */}
                                <Col span={6}>
                                    <span>Starting Chapter&nbsp;&nbsp;</span>
                                    <InputNumber value={startingChapter} onPressEnter={e => this.onChangeInputNumber(e, 'startingChapter')} />
                                </Col>
                                <Col span={6}>
                                    <span>Min Length of Prompt&nbsp;&nbsp;</span>
                                    <InputNumber value={promptLength} onPressEnter={e => this.onChangeInputNumber(e, 'promptLength')} />
                                </Col>
                                <Col span={6}>
                                    <span>Size of Text Chunk&nbsp;&nbsp;</span>
                                    <InputNumber value={chunkSize} onPressEnter={e => this.onChangeInputNumber(e, 'chunkSize')} />
                                </Col>
                            </Row>

                            <List
                                itemLayout="vertical"
                                size="large"
                                header={null}
                                footer={null}
                                dataSource={this.state.pageOfTexts}
                                pagination={{
                                    showSizeChanger: true,
                                    showLessItems: true,
                                    showTotal: total => `Total ${total} items`,
                                    showQuickJumper: true,
                                    defaultPageSize: 20,
                                }}
                                renderItem={item => (
                                    <List.Item key={item.id}

                                        actions={[<Button type="primary" onClick={() => this.onSelectText(item)}>Select</Button>]}
                                    >
                                        <Meta
                                            // avatar={<Avatar src={item.picture.large} />}
                                            title={item.prompt}
                                            description={title}
                                        />

                                        <Row type="flex" justify="center" style={{ marginTop: 20, marginLeft: 24, marginRight: 24, marginBottom: 12 }}>
                                            {item.originalText}
                                        </Row>

                                    </List.Item>
                                )}
                                style={{ margin: 40 }}
                            />
                        </Skeleton>
                    </TabPane>


                    <TabPane tab="Original Text" key="1">




                        <Card
                            style={{ marginTop: 40 }}
                        // actions={[<Icon type="caret-left" style={{fontSize: 30}} onClick={this.onPrev}/>, <Icon type="caret-right" style={{fontSize: 30}} onClick={this.onNext} />]}
                        >
                            <Card.Meta
                                avatar={
                                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                                }
                                title={<h2>{currentText && currentText.prompt}</h2>}
                                description={
                                    <span>
                                        {title}&nbsp;
                                    </span>
                                }
                            />

                            <Row type="flex" justify="start" style={{ marginTop: 40, marginLeft: 24, marginRight: 24, marginBottom: 40, fontSize: 16 }}>
                                {currentText && currentText.originalText && currentText.originalText.split('\n')
                                    .map((c, i) => <Typography.Paragraph key={i}>{c}</Typography.Paragraph>)}
                            </Row>

                            <Row type="flex" justify="center" style={{ marginTop: 20, marginLeft: 80, marginRight: 80, marginBottom: 40 }}>
                                <Button type="primary" onClick={() => this.setState({ activeTab: "2" })} style={{ width: "25%" }}>Try it</Button>
                            </Row>

                        </Card>



                    </TabPane>
                    <TabPane tab="Your recall" key="2">
                        <Row type="flex" justify="center" style={{ marginTop: 40, marginLeft: 80, marginRight: 80, marginBottom: 40 }}>
                            <h2>{currentText && currentText.prompt}</h2>
                        </Row>
                        <Row type="flex" justify="center" style={{ marginTop: 20, marginLeft: 80, marginRight: 80, marginBottom: 40 }}>
                            <TextArea showCount allowClear autoSize={{ minRows: 12 }} style={{ width: "100%" }} onChange={this.onRecallChange} />
                        </Row>
                        <Row type="flex" justify="center" style={{ marginTop: 20, marginLeft: 80, marginRight: 80, marginBottom: 40 }}>
                            <Button type="primary" onClick={this.onRecallSave} style={{ width: "25%" }}>Save &amp; Compare</Button>
                        </Row>
                    </TabPane>

                    <TabPane tab="Comparison" key="3">
                        {activeTab === '3' ? <>
                            <Row type="flex" justify="center" style={{ marginTop: 60, marginLeft: 80, marginRight: 80, marginBottom: 40, fontSize: 12 }}>
                                <ReactDiffViewer
                                    oldValue={(currentText && currentText.originalText) || ''}
                                    newValue={recall || ''}
                                    splitView={true}
                                    compareMethod='diffWords'
                                    useDarkTheme={true}
                                    leftTitle='Original Text'
                                    rightTitle='Your Input'
                                />
                            </Row>
                            <Row type="flex" justify="center" style={{ marginTop: 60, marginLeft: 80, marginRight: 80, marginBottom: 40 }}>
                                <Button type="primary" onClick={this.onRecallSubmit} style={{ width: "25%" }}>View History</Button>
                            </Row></> : null}
                    </TabPane>


                    <TabPane tab="History" key="4">
                        <List
                            itemLayout="vertical"
                            // size="large"
                            header={<Row type="flex" justify="center" style={{ marginTop: 30, marginRight: 24, marginBottom: 12, marginLeft: 24 }}>
                                <h1>Historical Attempts</h1></Row>}
                            footer={null}
                            dataSource={histories}
                            renderItem={item => (
                                <List.Item
                                    key={item.key}
                                    extra={<><Button type="primary"
                                        style={{ width: "100%", padding: '0px 12px 0px 0px', top: "40%" }}
                                        onClick={() => this.handleDelete(item.key)}>Delete</Button>
                                        <Button type="primary"
                                            style={{ width: "100%", padding: '0px 12px 0px 0px', top: "60%" }}
                                            onClick={() => this.handleTryAgain(item)}>Try Again</Button></>
                                    }

                                >
                                    <Meta
                                        // avatar={<Avatar src={item.picture.large} />}
                                        title={<span>{title}&nbsp;&nbsp;&nbsp;&nbsp;{moment.unix(item.createTime).format("YYYY-MM-DD")}</span>}
                                        description={item.prompt}
                                    />
                                    <Row type="flex" justify="center" style={{ marginTop: 20, marginLeft: 24, marginRight: 24, marginBottom: 12, fontSize: 12 }}>
                                        <ReactDiffViewer
                                            oldValue={item.originalText}
                                            newValue={item.recall}
                                            splitView={true}
                                            compareMethod='diffWords'
                                            useDarkTheme={true}
                                        />
                                    </Row>
                                </List.Item>
                            )}
                        />



                    </TabPane>

                </Tabs >






            </div >

        );
    }
}




export default App;

