import React, { Component } from 'react';
import {
  Form, Input, Button, Modal, message,
} from 'antd';
// import { QuestionCircleOutlined } from '@ant-design/icons';

import { withFirebase } from '../Firebase';
import styles from '../../index.css';

const INITIAL_STATE = {
  error: null,
  confirmDirty: false,
  loading: false,
};


class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }



  handleCancel = () => {
    this.setState({ alert: false });
    this.props.form.resetFields();
    console.log("reset fields");
    this.props.handleCancel();
  };



  reauthenticate = (firebase, currentPassword) => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
      user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }



  // onSubmit = async (e) => {
  onFinish = async (values) => {
    // const form = this.props.form;
    const { oldPassword, password } = values;


    // e.preventDefault();

    await this.setState({ loading: true });

    // this.props.form.validateFields((err, values) => {
    //   if (!err) {
    // console.log('Received values of form: ', values);

    this.props.firebase
      .doSignInWithEmailAndPassword(this.props.authUser.email, oldPassword)
      .then(() => {
        console.log('signed in');
        this.props.firebase
          .doPasswordUpdate(password)
          .then(() => this.props.handleCancel())
          .then(() => {
            message.success("Password changed", 2);
            this.props.onSubmit();
            this.setState({ ...INITIAL_STATE });
          })
          .catch(error => {
            this.setState({ error, loading: false });
            console.log('update error', error);
            message.error(error.message, 2);
          })
      }
      )
      .catch(error => {
        this.setState({ error, loading: false });
        console.log('sign in error', error);
        message.error(error.message, 2);
      })

    //   } else {
    //     this.setState({ loading: false });
    //     message.error('Please check your entries for error', 1);
    //     console.log("here's the validation error:", err);
    //   }
    // });


  }



  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  // compareToFirstPassword = (rule, value, callback) => {
  //   const form = this.props.form;
  //   if (value && value !== form.getFieldValue('password')) {
  //     callback('The passwords don\'t match');
  //     // this.setState({submitDisabled: true});
  //   } else {
  //     // this.setState({submitDisabled: false});
  //     callback();
  //   }
  // }

  // validateToNextPassword = (rule, value, callback) => {
  //   const form = this.props.form;
  //   if (value && this.state.confirmDirty) {
  //     form.validateFields(['confirm'], { force: true });
  //   }
  //   callback();
  // }


  // compareToFirstPassword = async (rule, value) => {
  //   const form = this.props.form;
  //   if (value && value !== form.getFieldValue('password')) {
  //     // callback('The passwords don\'t match');
  //     throw new Error('The passwords don\'t match');
  //     // this.setState({submitDisabled: true});
  //   }
  // }


  // validateToNextPassword = async (rule, value, callback) => {
  //   const form = this.props.form;
  //   if (value && this.state.confirmDirty) {
  //     form.validateFields(['confirm'], { force: true });
  //   }
  //   // callback();
  // }



  render() {



    return (


      <Modal
        visible={this.props.visible}
        title="Edit My Password"
        onCancel={this.handleCancel}  //remove this so that pressing esc doesn't bring in the close of the modal
        footer={null}
        closable={true}
        maskClosable={false}
      >

        <Form className={styles["login-form"]} onFinish={this.onFinish} name="pwchange" form={this.props.form}>

          {/* <Form.Item
            name="password"
            label={(
              <span>
                New Password&nbsp;
                <Tooltip title="Use letters, numbers and special characters">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            )}
            validateTrigger={['onBlur']}
            rules={[{
              required: true, message: 'Password must be at least 6 characters', min: 6,
            }, {
              validator: this.validateToNextPassword,
            }]}
          >
            <Input.Password name="passwordOne" type="password" />
          </Form.Item>
          <Form.Item
            // {...formItemLayout}
            label="Confirm New Password"
            name='confirm'
            validateTrigger={['onBlur']}
            rules={[{
              required: true, message: 'Password must be at least 6 characters',
            }, {
              validator: this.compareToFirstPassword,
            }]}
          >
            <Input.Password name="passwordTwo" type="password" onBlur={this.handleConfirmBlur} />
          </Form.Item> */}

          <Form.Item
            name="oldPassword"
            label="Current Password"
            // labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Please input your current password',
              }
            ]}
            hasFeedback
          >
            <Input.Password autoComplete="off" />
          </Form.Item>

          <Form.Item
            name="password"
            label="New Password"
            // labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
              {
                min: 6,
                message: 'Password must be minimum 6 characters.'
              }
            ]}
            hasFeedback
          >
            <Input.Password autoComplete="off" />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            // labelAlign="left"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password autoComplete="off" />
          </Form.Item>


          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
              Reset My Password
            </Button>

          </Form.Item>


        </Form>
      </Modal>


    );
  }
}



// const WrappedPwChangeForm = Form.create({ name: 'pwchange' })(PasswordChangeForm);

const WrappedPwChangeForm = (props) => {
  const [form] = Form.useForm();
  return <PasswordChangeForm form={form} {...props} />
}


export default withFirebase(WrappedPwChangeForm);