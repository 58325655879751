import axios from 'axios';
// import { useCancellablePromise } from '../Hooks';
import { message } from 'antd';


// const QUIZ_LOGGING_URL = process.env.NODE_ENV === 'production'
//   ? 'https://us-central1-kmtrek-7e8d7.cloudfunctions.net/silkapi/logquiz'
// : 'http://localhost:5001/kmtrek-7e8d7/us-central1/silkapi/logquiz';



const USER_CREATION_URL = process.env.NODE_ENV === 'production'
    ? 'https://us-central1-econo-45f8f.cloudfunctions.net/api/signup'
    : 'http://localhost:5001/econo-45f8f/us-central1/api/signup';


const CAPTCHA_VERIFICATION_URL = process.env.NODE_ENV === 'production'
    ? 'https://us-central1-econo-45f8f.cloudfunctions.net/api/verify'
    : 'http://localhost:5001/econo-45f8f/us-central1/api/verify';


const SAVE_BOOK_URL = process.env.NODE_ENV === 'production'
    ? 'https://us-central1-econo-45f8f.cloudfunctions.net/saveBook'
    : 'http://localhost:5001/econo-45f8f/us-central1/api/saveBook';


const BORROW_BOOK_URL = process.env.NODE_ENV === 'production'
    ? 'https://us-central1-econo-45f8f.cloudfunctions.net/overdrive_borrow'
    : 'https://us-central1-econo-45f8f.cloudfunctions.net/overdrive_borrow';

export const createUser = (authuser, referrer, username, email) =>
    axios.post(USER_CREATION_URL,
        {
            uid: authuser.user.uid,
            referrer,
            username,
            email
        });



export const verifyCaptcha = (captchatoken) =>
    axios.post(CAPTCHA_VERIFICATION_URL,
        {
            captchatoken
        });


// export const saveBook = (bookinfo, contentType) =>
//     axios.post(SAVE_BOOK_URL,
//         {
//             data: bookinfo
//         },
//         {
//             headers: {
//                 'Content-Type': contentType,
//             }
//         }
//     );

// axios automatically uses multipart/form data header and adds boundary, if data is a FormData
export const saveBook = (bookinfo) =>
    axios.post(SAVE_BOOK_URL, bookinfo);




// export const logQuiz = (data, uid) =>
// axios.post(QUIZ_LOGGING_URL, {
// data,
// uid
// });


export const borrowBook = (bookname, author) =>
    axios.post(BORROW_BOOK_URL,
        {
            bookname,
            author
        })
        .then(response => {
            return response
        }
        ).catch(err => {
            message.error('error', 5);
            return err
        });

