import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Menu, Drawer, Button, Row } from "antd";
import Icon, {
  BellOutlined,
  UserOutlined,
  // EditOutlined,
  // BookOutlined,
  LogoutOutlined,
  LoginOutlined,
  HomeOutlined,
  ReadOutlined,
  YoutubeOutlined
} from "@ant-design/icons";


import { CgPiano } from "react-icons/cg";
import { GiBookshelf } from "react-icons/gi";
import { VscDiff } from "react-icons/vsc";

// import logo from '../../constants/secresilk-logo.png';

import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import SignInForm from "../SignIn";
import SignUpForm from "../SignUp";
import PasswordForgetForm from "../PasswordForget";
import { withFirebase } from "../Firebase";


const SubMenu = Menu.SubMenu;

// const SigninMenu = SignInForm(Menu.Item);
const modalList = ["signin", "signup", "pwforget"];

// Place react component in menubar; vanilla html would result in many console errors
// const LogoImage = () => <img style={{ height: "50px", paddingLeft: 12 }} src={logo} alt="Logo" />;

class NavigationBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: "login",
      // signinSpin: false,
      // signinVisible: (this.props.location && this.props.location.state && this.props.location.state.visible) || false,
      signinVisible: false,
      signupVisible: false,
      pwforgetVisible: false,
      drawerVisible: false,
    };
  }

  handleClick = (e) => {
    // console.log('click ', e);
    this.setState({
      current: e.key,
      drawerVisible: false,
    });
  };

  showModal = (key) => {
    const obj = Object.assign(
      ...modalList.map((i) => ({ [`${i}Visible`]: i === key }))
    );
    this.setState(obj);
  };

  cancelModal = (key) => {
    const stateName = `${key}Visible`;

    this.setState({
      [stateName]: false,
    });
  };

  showDrawer = () => {
    this.setState({ drawerVisible: true });
  };

  onCloseDrawer = () => {
    this.setState({ drawerVisible: false });
  };

  render() {
    const {
      signinVisible,
      signupVisible,
      pwforgetVisible,
      drawerVisible,
    } = this.state;

    return (
      <Fragment>
        <Row
          type="flex"
          justify="end"
        //   style={{ marginTop: 20, marginLeft: 20 }}
        >
          <Button onClick={this.showDrawer}>Menu</Button>
        </Row>

        <Drawer
          title={false}
          placement="right"
          closable={true}
          onClose={this.onCloseDrawer}
          visible={drawerVisible}
        >
          <AuthUserContext.Consumer>
            {(authUser) =>
              authUser ? (
                <Menu
                  mode="inline"
                  // style={{ width: 256 }}
                  style={{ border: "none" }}
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  {/* <Menu.Item key="logo">
          <LogoImage />
          </Menu.Item> */}
                  <Menu.Item key="home">
                    <Link to={ROUTES.BORROW}>
                      <HomeOutlined />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="bookshelf">
                    <Link to={ROUTES.BOOKSHELF}>
                      <Icon component={GiBookshelf} />&nbsp;&nbsp;&nbsp;
                      Bookshelf
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="pianovideos">
                    <Link to={ROUTES.PIANOVIDEOS}>
                      <Icon component={CgPiano} />&nbsp;&nbsp;&nbsp;
                      Piano
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="read">
                    <Link to={ROUTES.READ}>
                      <ReadOutlined />&nbsp;&nbsp;&nbsp;
                      Read
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="pianovideos">
                    <Link to={ROUTES.PIANOVIDEOS}>
                      <YoutubeOutlined />&nbsp;&nbsp;&nbsp;
                      Piano Videos
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key="textcompare">
                    <Link to={ROUTES.TEXTCOMPARE}>
                      <VscDiff />&nbsp;&nbsp;&nbsp;
                      Compare Texts
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="account"
                    title={
                      <span className="submenu-title-wrapper">
                        <UserOutlined />&nbsp;&nbsp;&nbsp;
                        My Account
                      </span>
                    }
                  >
                    <Menu.Item key="profile">
                      <Link to={ROUTES.PROFILE}>
                        <ReadOutlined />&nbsp;&nbsp;&nbsp;
                        Profile
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="pfo">
                      <Link to={ROUTES.SETTINGS}>
                        <ReadOutlined />&nbsp;&nbsp;&nbsp;
                        Settings
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="cred">
                      <Link to={ROUTES.CRED}>
                        <ReadOutlined />&nbsp;&nbsp;&nbsp;
                        Credentials
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      key="logout"
                      onClick={this.props.firebase.doSignOut}
                    >
                      <LogoutOutlined />&nbsp;&nbsp;&nbsp;
                      Log Out
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="notifications">
                    <BellOutlined />
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  mode="inline"
                  // style={{ width: "100%" }}
                  style={{ border: "none" }}
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  {/* <LogoImage /> */}
                  <Menu.Item key="home">
                    <Link to={ROUTES.BOOKSHELF}>
                      <HomeOutlined />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    title={
                      <span className="submenu-title-wrapper">
                        <UserOutlined />
                        My Account
                      </span>
                    }
                  >
                    <Menu.Item
                      key="login"
                      onClick={() => this.showModal("signin")}
                    >
                      <LoginOutlined />
                      Log in
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              )
            }
          </AuthUserContext.Consumer>
        </Drawer>

        {/* <AuthUserContext.Consumer> */}
        <Fragment>
          <SignUpForm
            visible={signupVisible}
            handleCancel={() => this.cancelModal("signup")}
          />
          <SignInForm
            visible={signinVisible}
            handleCancel={() => this.cancelModal("signin")}
            handleSignup={() => this.showModal("signup")}
            handlePwforget={() => this.showModal("pwforget")}
          />
          <PasswordForgetForm
            visible={pwforgetVisible}
            handleCancel={() => this.cancelModal("pwforget")}
          />
        </Fragment>
        {/* </AuthUserContext.Consumer> */}
      </Fragment>
    );
  }
}

const App = (props) => {
  return <NavigationBase {...props} />;
};

const Navigation = compose(withRouter, withFirebase)(App);

export default Navigation;
