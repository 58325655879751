import React, { useState, useEffect } from "react";
// import { ReactReader } from "react-reader";
// import { Button, Row } from "antd";

import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
// import DataTable from './table';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";

import Reader from "./reader";

function AppBase(props) {
  const [fileref, setFileref] = useState(null);

  // if (props.location && props.location.state) {
  //   const { itemKey, booklocation, fileUrl } = props.location.state;
  // }

  const fileUrl =
    props.location && props.location.state && props.location.state.fileUrl;
  const itemKey =
    props.location && props.location.state && props.location.state.itemKey;
  const bookLoc =
    props.location && props.location.state && props.location.state.bookLoc;

  const [loc, setLoc] = useState(bookLoc);

  // let { fileUrl } = useParams();

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      // console.log(props.location.search);

      // const raw = props.location.search;

      // console.log(props.match.params);

      // console.log(props.location.state);

      if (fileUrl) {
        // const raw = props.match.params.fd + "/" + props.match.params.fileUrl;

        // console.log(raw);

        // const child = raw.split("=").slice(1).join("=");

        const storageRef = props.firebase.storage.ref();

        //   storageRef
        //     .child(child)
        //     .getDownloadURL()
        //     .then(function (url) {
        //       // `url` is the download URL for 'images/stars.jpg'

        //       // This can be downloaded directly:
        //       var xhr = new XMLHttpRequest();
        //       xhr.responseType = "blob";
        //       xhr.onload = function (event) {
        //         var blob = xhr.response;
        //       };
        //       xhr.open("GET", url);
        //       xhr.send();

        //       // Or inserted into an <img> element:
        //       var img = document.getElementById("myimg");
        //       img.src = url;
        //     })
        //     .catch(function (error) {
        //       console.log(error);
        //     });

        storageRef
          .child(fileUrl)
          .getDownloadURL()
          .then((fr) => {
            setFileref(fr);
          });
      }
    }

    return () => (isSubscribed = false);
  }, []);

  return (
    <React.Fragment>
      <div style={{ marginTop: 20, marginRight: 40 }}>
        <Reader
          {...props}
          fileref={fileref}
          itemKey={itemKey}
          fileUrl={fileUrl}
          dbrefname="userbookshelf"
          bookLoc={loc}
          setLoc={setLoc}
        />
      </div>
    </React.Fragment>
  );
}

const ExtendedAppBase = compose(withRouter, withFirebase)(AppBase);

const App = () => {
  return (
    <AuthUserContext.Consumer>
      {(authUser) => <ExtendedAppBase authUser={authUser} />}
    </AuthUserContext.Consumer>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(App);
