import React, { Component, Fragment } from "react";

import DataTable from "./table";
import Uploader from "./epub-uploader";
// import ImgUploader from './img-uploader-cloud';

import { message, Row } from "antd";

// import {
//     PlusOutlined,
//     QuestionCircleOutlined
// } from '@ant-design/icons';

import moment from "moment";

import { makeCancelable } from "../Hooks";

// const { Title } = Typography;

// import { attris, colormapping } from '../../constants/autocomplete';

// const maxlabellength = 25;

// const arrayToObject = (array, keyField) =>
//     array.reduce((obj, item) => {
//         obj[item[keyField]] = item
//         return obj
//     }, {})

// const save2list = arrayToObject(attris, "savelabel");

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // searchTerm: [],
      error: null,
      iniloading: true,
      loading: false,
      // termObject: {},
      // visible: false,
      dbLoading: false,
      // modalTitle: '',
      // modalType: '',
      // pushkey: null,
      data: {},
      tableData: [],
      createTime: null,
      justUploaded: false,
    };
  }

  pendingPromises = [];

  // initialChildName = 'name';

  componentWillUnmount = () => this.pendingPromises.map((p) => p.cancel());

  appendPendingPromise = (promise) =>
    (this.pendingPromises = [...this.pendingPromises, promise]);

  removePendingPromise = (promise) =>
    (this.pendingPromises = this.pendingPromises.filter((p) => p !== promise));

  componentDidMount = () => {
    const dbref = this.props.firebase.db.ref(
      `${this.props.dbrefname}/${this.props.authUser.uid}`
    );

    // const ref = this.props.firebase[this.props.refname];
    // const query = ref(this.props.authUser.uid);

    const wrappedPromise = makeCancelable(dbref.once("value"));
    this.appendPendingPromise(wrappedPromise);
    this.setState({ loading: true });

    wrappedPromise.promise
      .then((snapshot) => snapshot.val())
      .then((rawdata) => {
        if (Object.keys(rawdata).length > 0) {
          const tableData = Object.entries(rawdata).map(([k, t], i) => ({
            id: k,
            title: t.title,
            submitTime: t.submitTime,
            creator: t.creator,
            publisher: t.publisher,
            pubdata: t.pubdate,
            lastreadTime: t.lastreadTime || null,
            readLoc: t.readLoc,
            // createTimeCal: moment(t.createTime * 1000).format('MMMM Do YYYY, h:mm:ss a'),
            // createTimeText: moment(t.createTime * 1000).fromNow(),
            // title: t.title,
            description: t.description || "",
            fileUrl: t.fileUrl,
            // tags: t.tags || {}
          }));

          // .filter(td => td.name || td.shortname || td.completionDate);

          this.setState({ tableData, loading: false });
          this.removePendingPromise(wrappedPromise);
        }
      })
      .catch((errorInfo) => {
        if (!errorInfo.isCanceled) {
          this.setState({ error: errorInfo.error, loading: false });
          this.removePendingPromise(wrappedPromise);
        }
      });
  };

  onDismissTerm = (savelabel) => {
    const { uid, refname } = this.props;
    const { termObject } = this.state;

    let newto = { ...termObject }; //clone object
    delete newto[savelabel];

    this.setState({ termObject: newto });
    this.removeFromDb(uid, savelabel, refname);
  };

  // onSelect = (savelabel) => {
  //     // we should operate with savelabel, not displaylabel

  //     if (savelabel.length > maxlabellength) {
  //         message.error('Description too long', 1);
  //     } else {
  //         this.setState((state, props) => ({
  //             searchTerm: [...state.searchTerm, savelabel]
  //         }));
  //     }
  // }

  // onDeselect = (savelabel) => {
  //     // we should operate with savelabel, not displaylabel

  //     this.setState({
  //         searchTerm: this.state.searchTerm.filter(t => t !== savelabel),
  //         // termHistory,
  //     });
  // }

  removeFromDb(uid, label, refname) {
    const ref = this.props.firebase[refname];
    return ref(uid)
      .child(label)
      .remove()
      .catch((error) => {
        this.setState({ error });
      });
  }

  // onClick = () => {

  //     const { searchTerm, termObject } = this.state;
  //     const { uid, refname } = this.props;
  //     const ref = this.props.firebase[refname];
  //     let termsObj;
  //     const cleanterms = [...new Set(searchTerm.map(i => i.trim()))];

  //     if (searchTerm.length < 1) {
  //         return;
  //     }

  //     // console.log('termHistory:', termHistory);
  //     termsObj = Object.assign(...cleanterms.map(i => ({ [i]: ((save2list[i] && save2list[i].aspect) || 'other') })));

  //     this.setState({
  //         searchTerm: [],
  //         termObject: { ...termObject, ...termsObj },
  //         visible: false,
  //         dbLoading: false,
  //     });

  //     ref(uid)
  //         .update(termsObj)
  //         .catch(error => {
  //             this.setState({ error });
  //             console.log(error);
  //         });

  // }

  // handleCancel = (itemkey, del) => {
  //   this.setState({
  //     visible: false,
  //   });
  //   if (del) {
  //     console.log("deleting", itemkey);
  //     this.handleDelete(itemkey);
  //   }
  // };

  afterFileUpload = (obj) => {
    const submitTime = Math.round(moment().format("X"));
    const submitObj = { ...obj, submitTime };

    this.setState({ loading: true, error: null, justUploaded: true });

    const dbref = this.props.firebase.db.ref(
      `${this.props.dbrefname}/${this.props.authUser.uid}`
    );
    dbref
      .push(submitObj)
      .then((snap) => {
        // this.setState({loading: false, itemKey: snap.key});
        this.setState((state, props) => ({
          tableData: [...state.tableData, { ...submitObj, id: snap.key }],
          // tableData: [...state.tableData.filter(d => d.id !== snap.key), { ...submitObj, id: snap.key }],
          loading: false,
          pushkey: snap.key,
        }));
        message.success("Book info saved");
      })
      .catch((error) => {
        this.setState({ error, loading: false });
        console.log("update error", error);
        message.error(error.message, 2);
      });
  };

  handleSave = (id, newdata) => {
    const oldItem =
      this.state.tableData.filter((d) => d.id === id).length > 0
        ? this.state.tableData.filter((d) => d.id === id)[0]
        : {};
    this.setState((state, props) => {
      const newItem =
        state.modalType === "create"
          ? {
            ...oldItem,
            ...newdata,
            createTime: state.createTime,
            // createTimeCal: moment(state.createTime * 1000).format('MMMM Do YYYY, h:mm:ss a'),
            // createTimeText: moment(state.createTime * 1000).fromNow(),
          }
          : { ...oldItem, ...newdata };

      return {
        visible: false,
        tableData: [newItem, ...state.tableData.filter((d) => d.id !== id)],
      };
    });
  };

  // handleCreate = () => {
  //     const dbref = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}`);
  //     const submitTime = Math.round(moment().format('X'));

  //     const wrappedPromise = makeCancelable(dbref.push({
  //         [this.initialChildName]: this.props.initialText,
  //         createTime: submitTime
  //     }));

  //     this.setState({ data: {}, dbLoading: true, modalType: 'create' });

  //     wrappedPromise.promise
  //         .then(snap => {
  //             this.setState({ pushkey: snap.key, dbLoading: false, createTime: submitTime });
  //             this.removePendingPromise(wrappedPromise);
  //             message.success("Created a goal of default title");
  //             this.showModal("Create a new quarterly goal");
  //         })
  //         .catch(errorInfo => {
  //             if (!errorInfo.isCanceled) {
  //                 this.setState({ error: errorInfo.error, dbLoading: false });
  //                 console.log('push error', errorInfo.error);
  //                 this.removePendingPromise(wrappedPromise);
  //                 message.error(errorInfo.message, 2);
  //             } else {
  //                 console.log("data fetch is cancelled");
  //             }
  //         });

  //     // this.showModal("Create a new quarterly goal");

  // }

  // handleEdit = (itemkey) => {
  //     this.setState({ dbLoading: true, modalType: 'edit', pushkey: itemkey, visible: true, modalTitle: "Edit" });
  // }

  handleDelete = (itemkey, fileUrl) => {
    const dbref = this.props.firebase.db.ref(
      `${this.props.dbrefname}/${this.props.authUser.uid}/${itemkey}`
    );
    const storageRef = this.props.firebase.storage.ref();
    const delfileRef = storageRef.child(fileUrl);
    // const query = ref(this.props.uid);
    const wrappedPromise = makeCancelable(dbref.remove());
    this.appendPendingPromise(wrappedPromise);
    const wrappedPromise2 = makeCancelable(delfileRef.delete());
    this.appendPendingPromise(wrappedPromise2);
    this.setState({
      dbLoading: true,
      tableData: this.state.tableData.filter((d) => d.id !== itemkey),
    });

    // wrappedPromise.promise
    Promise.all([wrappedPromise, wrappedPromise2])
      .then(() => {
        this.removePendingPromise(wrappedPromise);
        this.removePendingPromise(wrappedPromise2);
        message.success("deleted file", 5);
      })
      .catch((errorInfo) => {
        if (!errorInfo.isCanceled) {
          this.setState({ error: errorInfo.error });
          this.removePendingPromise(wrappedPromise);
        } else {
          console.log("book deletion is cancelled");
        }
      });
    this.setState({ dbLoading: false });
  };

  render() {
    const {
      loading,
      // searchTerm,
      // termObject,
      // visible,
      dbLoading,
      // modalTitle,
      // pushkey,
      // data,
      tableData,
      justUploaded,
      // modalType
    } = this.state;

    // const termTags = termObject && Object.entries(termObject).map(i => <Tag key={i[0]} style={{ marginTop: 6 }}
    //     closable afterClose={() => this.onDismissTerm(i[0])} color={colormapping[i[1]]}>
    //     <Tooltip title={i[1]}>
    //         {(save2list[i[0]]
    //             && save2list[i[0]].displaylabel) || i[0]}
    //     </Tooltip></Tag>);

    return (
      <Fragment>
        {/* <Row
          type="flex"
          justify="center"
        >
          <Title level={1}>My Books</Title>
        </Row> */}
        <Row
          type="flex"
          justify="center"
        //   style={{ marginTop: 20, marginLeft: 20 }}
        >
          <Uploader
            firebase={this.props.firebase}
            authUser={this.props.authUser}
            afterFileUpload={this.afterFileUpload}
            existingData={tableData}
          />
        </Row>
        {/* <Row
          type="flex"
          justify="center"
          //   style={{ marginTop: 20, marginLeft: 20 }}
        > */}
        <DataTable
          {...this.props}
          // refname="usergoals"
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
          dbLoading={dbLoading}
          data={tableData}
          // data={[]}
          loading={loading}
          justUploaded={justUploaded}
        />
        {/* </Row> */}
      </Fragment>
    );
  }
}

export default App;
