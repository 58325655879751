import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Navigation from "../Navigation";
// import Borrow from "../Borrow";
import Read from "../Read";
import Bookshelf from "../Bookshelf";
import Home from "../Home";
import Profile, { LoginCredPage, SettingsPage } from "../Account";
import PianoVideos from '../PianoVideos';
import PlayVideo from '../PlayVideo';

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

import "../../App.less";
import TextCompare from "../TextCompare";

const App = () => (

  <Router>
    <Navigation />
    {/* <hr /> */}

    {/* <div style={{margin: "auto", padding: 40}}> */}
    <Switch>
      {/* <Route exact path={ROUTES.BORROW} component={Borrow} /> */}
      <Route exact path={ROUTES.READ} component={Read} />
      <Route exact path={ROUTES.BOOKSHELF} component={Bookshelf} />
      <Route exact path={ROUTES.PROFILE} component={Profile} />
      <Route exact path={ROUTES.CRED} component={LoginCredPage} />
      <Route exact path={ROUTES.SETTINGS} component={SettingsPage} />
      <Route exact path={ROUTES.HOME} component={Home} />
      <Route exact path={ROUTES.PIANOVIDEOS + '/:filters?'} component={PianoVideos} />
      <Route exact path={ROUTES.PLAYVIDEO + '/:piece/:video'} component={PlayVideo} />
      <Route exact path={ROUTES.TEXTCOMPARE} component={TextCompare} />
      {/* <Route path="/sample1.html" onEnter={reload} /> */}
      <Redirect to="/" />
    </Switch>
    {/* </div> */}
    {/* <hr/> */}
  </Router>

);

export default withAuthentication(App);
