import React, { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import elasticlunr from "elasticlunr";

import { Button, Table, Input, Row, Skeleton, Space, Tag, Rate, message, Badge } from "antd";

import { useHistory, useParams, useLocation } from 'react-router-dom';

import {
    SearchOutlined,
} from "@ant-design/icons";

import Moment from "react-moment";
import moment from 'moment';

import PartsList from './parts-list';

import * as ROUTES from "../../constants/routes";

const { Search } = Input;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function DataTable(props) {
    const [projectsearchtext, setProjectearchtext] = useState("");
    const [searchText, setSearchText] = useState("");
    const [inputVal, setInputVal] = useState("");
    // const [searchedColumn, setSearchedColumn] = useState('');

    const [shown, setShown] = useState([]);
    // "columnFilters" records what kind of column filters are currently applied
    const [columnFilters, setColumnFilters] = useState(props.filterObj);
    const [expandrows, setExpandrows] = useState([]);

    const query = useQuery();

    const [selectedRating, setSelectedRating] = useState(query.get("rating") || null);
    const [selectedLevel, setSelectedLevel] = useState(query.get("level") || null);

    const bookEl = useRef(null);
    const bookclearEl = useRef(null);
    const refSearchBox = useRef(null);

    const colormapping = {
        'beginner': 'lime',
        'easy': 'cyan',
        'intermediate': 'gold',
        'hard': 'magenta'
    };

    const filteredColumns = ['level', 'rating'];

    const fi = Object.assign(...filteredColumns.map(
        name => {
            const vals = (props.data && props.data.map(rd => rd[name])) || [];
            // const objvals = (props.data && props.data.map(rd => Object.keys(rd[name]))) || [];
            // const vals = objvals.flat();
            const uniqueVals = Array.from(new Set(vals));
            return {
                [name]: uniqueVals.map(val => ({
                    // text: val.toString().charAt(0).toUpperCase() + val.toString().slice(1),
                    text: val && val.toString(),
                    value: val
                }))
            }
        }
    ));

    // fil is for each column fileter, what value options there would be to filter

    const projidx = elasticlunr(function () {
        this.addField("name");
        this.setRef("key");

        props.data.forEach((p) => {
            this.addDoc(p);
        });
    });

    const renderTitle = (query, text, row) => (
        // <Link
        //     to={{
        //         pathname: `/read`,
        //         // search: `?book=${row.fileUrl}`,
        //         state: {
        //             fileUrl: row.fileUrl,
        //             itemKey: row.id,
        //             bookLoc: row.readLoc || 0,
        //         },
        //     }}
        // >
        <Badge dot={row.inProgress} color="green">
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[query, projectsearchtext]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
                onClick={onClickTitle}
            />
        </Badge>
        // </Link>
    );

    // const renderTitle = (query, text, row) => (

    //         <Highlighter
    //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //             searchWords={[query, projectsearchtext]}
    //             autoEscape
    //             textToHighlight={text? text.toString(): ''}
    //         />

    // );

    const onClickTitle = e => {
        e.preventDefault();
        // probably needs to write a separate, small component, like the popInput in parts-list.js

    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        // setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    // ref={refs[dataIndex]}
                    ref={bookEl}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        ref={bookclearEl}
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
            // <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                // setTimeout(() => searchInput.select());
                // setTimeout(() => refs[dataIndex].current.select(), 50);
                setTimeout(() => bookEl.current.select(), 50);
            } else {
                // console.log(bookEl.current);
                bookEl.current.input.value = "";
            }
        },
        render: (text, record) => renderTitle(searchText, text, record),
        // (
        //     <Fragment>
        //         <Highlighter
        //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //             searchWords={[searchText, projectsearchtext]}
        //             autoEscape
        //             textToHighlight={text.toString()}
        //         />
        //         {renderTitle(searchText, text, record)}
        //     </Fragment>
        //     // #ff01bb
        // ),
    });

    const clearAllFilters = async () => {
        bookclearEl.current && bookclearEl.current.click();
        setColumnFilters({});
        setProjectearchtext("");
        setExpandrows([]);
        setInputVal("");
        props.history.push(ROUTES.PIANOVIDEOS);
    };


    const onRatingChange = (val, key) => {
        console.log('new rating is', val);
        const submitTime = Math.round(moment().format('X'));

        const dbref = props.firebase.db.ref(`userpianovideos/${props.authUser.uid}/${key}`);

        dbref.update({ rating: val, submitTime })
            .catch(error => {
                console.log('Rating update error', error);
                message.error(error.message, 2);
            }).then(() => {
                message.success("Rating saved");
            });

        props.handleSave(key, { rating: val, submitTime });
    }



    const handleLinkSave = value => (piece, video, val) => {

        const submitTime = Math.round(moment().format('X'));
        const oldItemIdx = value.findIndex(d => d.id === video);
        const newItem = { ...value[oldItemIdx], link: val };
        const newValue = [...value.slice(0, oldItemIdx), newItem, ...value.slice(oldItemIdx + 1)]
        props.handleSave(piece, { submitTime, videos: newValue });

    }


    // const onClickLevelTag = (e, key) => {
    //     e.preventDefault();

    //     const dbref = props.firebase.db.ref(`userpianovideos/${props.authUser.uid}/${key}`);

    //     dbref.update({ level: 'beginner' })
    //         .catch(error => {
    //             console.log('Level update error', error);
    //             message.error(error.message, 2);
    //         }).then(() => {
    //             message.success("Level saved");
    //         });

    //     // props.handleSave(key, { rating: val });
    // }


    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            width: 350,
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            sortDirections: ["descend", "ascend"],
            filteredValue: columnFilters.name || null,
            ...getColumnSearchProps("name"),
        },
        {
            title: "Composer",
            dataIndex: "composer",
            width: 200,
            sorter: (a, b) => {
                return a.composer.localeCompare(b.composer);
            },
            sortDirections: ["descend", "ascend"],
            filteredValue: columnFilters.composer || null,
            ...getColumnSearchProps("composer"),
        },
        {
            title: 'Level',
            dataIndex: 'level',
            width: 80,
            filters: fi['level'],
            // defaultFilteredValue: (props.filterObj && [props.filterObj.level]) || [],
            filteredValue: columnFilters.level || null,
            onFilter: (value, record) => record.level === value,
            render: (value, record) => <Tag color={colormapping[value]}
            // onClick={e => onClickLevelTag(e, record.key)}
            >{value}</Tag>
        },
        {
            title: 'Video Links',
            dataIndex: 'videos',
            width: 120,
            render: (value, record) => <PartsList
                piece={record.key}
                value={value}
                rating={record.rating}
                handleSave={handleLinkSave(value)}
                firebase={props.firebase}
                authUser={props.authUser} />

        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            width: 170,
            filters: fi['rating'],
            // defaultFilteredValue: (props.filterObj && [props.filterObj.rating]) || [],
            // defaultFilteredValue: ["4"],
            filteredValue: columnFilters.rating || null,
            onFilter: (value, record) => record.rating === value,
            sorter: (a, b) => { return a.rating - b.rating },
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => <Rate allowClear={true} value={value} onChange={val => onRatingChange(val, record.key)} />
        },
        {
            title: "Last Updated",
            className: "column-time",
            dataIndex: "submitTime",
            width: 170,
            // defaultSortOrder: "descend",
            // defaultSortOrder: props.justUploaded ? null : "descend",
            sorter: (a, b, sortOrder) => {
                // always sort the null ones to the bottom
                if (a.submitTime != null && b.submitTime != null) {
                    return a.submitTime - b.submitTime;
                }
                if (a.submitTime) {
                    return sortOrder === "ascend" ? -1 : 1;
                }
                if (b.submitTime) {
                    return sortOrder === "ascend" ? 1 : -1;
                }
                return 0;
            },
            sortDirections: ["descend", "ascend"],
            render: (value, record) =>
                value ? (
                    <Moment interval={30000} fromNow>
                        {value * 1000}
                    </Moment>
                ) : (
                    <span></span>
                ),
        },
    ];

    const handleChange = (pagination, filters, sorter, extra) => {
        console.log("params: ", pagination, filters, sorter, extra);
        const filterString = Object.entries(filters).filter(([k, v]) => v !== null).map(([k, v]) => k + '=' + v).join("&");
        console.log(filterString);
        props.history.push(ROUTES.PIANOVIDEOS + "/" + filterString);
        setColumnFilters(filters);
        console.log(filters);
    };

    const onSearchInputChange = (e) => {
        e.preventDefault();
        setInputVal(e.target.value);
    };

    const onAllcolSearch = async (query) => {
        setProjectearchtext(query);
        const results = projidx && projidx.search(query);
        // console.log(results);
        // const rownums = results.map(r => Number.parseInt(r.ref, 10));
        // here rownums are not necessarily numbers, can be string, depending on id
        const rownums = results.map((r) => r.ref);
        setExpandrows(rownums);
        const showdata = props.data.filter((p) => rownums.includes(p.key));
        setShown(showdata);
    };

    const onExpand = (expanded, record) => {
        expanded
            ? setExpandrows([...expandrows, record.id])
            : setExpandrows(expandrows.filter((e) => e !== record.id));
    };

    // const rowSelection = {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    //     },
    //     getCheckboxProps: (record) => ({
    //         disabled: record.name === 'Disabled User',
    //         // Column configuration not to be checked
    //         name: record.name,
    //     }),
    // };


    return (
        <React.Fragment>
            <Row
                type="flex"
                justify="center"
                style={{ marginTop: 20, marginBottom: 20 }}
            >
                <Search
                    ref={refSearchBox}
                    placeholder="search among your music"
                    onSearch={onAllcolSearch}
                    enterButton
                    style={{ width: 400, margin: "auto" }}
                    allowClear
                    value={inputVal}
                    onChange={onSearchInputChange}
                />
            </Row>

            <Row
                type="flex"
                justify="start"
                style={{ marginTop: 0, marginBottom: 0 }}
            >
                {/* check if array contains any non-falsy values, use arr.some(el => !!el) --  */}
                <Button
                    type="primary"
                    onClick={clearAllFilters}
                    className={
                        Object.values(columnFilters).every((el) => !el) &&
                            projectsearchtext === ""
                            ? "invisible-button"
                            : ""
                    }
                >
                    Clear filters
                </Button>
            </Row>

            {props.loading ? (
                <Row
                    type="flex"
                    justify="center"
                    style={{ marginTop: 40, marginBottom: 40, width: "100%" }}
                >
                    <Skeleton
                        active
                        title={false}
                        paragraph={{ rows: 4, width: ["100%", "100%", "100%", "100%"] }}
                    />
                </Row>
            ) : (
                <Row
                    type="flex"
                    justify="center"
                    style={{ marginTop: 40, marginBottom: 40 }}
                >
                    <Table
                        locale={{ emptyText: <h1> No Music </h1> }}
                        rowKey="key"
                        columns={columns}
                        dataSource={projectsearchtext === "" ? props.data : shown}
                        // pagination={false}
                        pagination={{ position: ["topRight", "bottomRight"], pageSize: 20 }}
                        // scroll={{ y: 640 }}
                        // expandedRowRender={record => {
                        //   const newhtml = ReactDOMServer.renderToStaticMarkup(<Highlighter
                        //     highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                        //     searchWords={[projectsearchtext]}
                        //     autoEscape
                        //     textToHighlight={DOMPurify.sanitize(record.description)}
                        //   />);
                        //   console.log(newhtml);
                        //   return (<div dangerouslySetInnerHTML={{ __html: newhtml }}>
                        //   </div>)
                        // }}

                        // expandedRowRender={record => <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record.description) }}>
                        // </div>}

                        // title={() => 'Sort and filter; click "+" to see description'}
                        // rowSelection={{
                        //     type: 'checkbox',
                        //     ...rowSelection,
                        // }}
                        onChange={handleChange}
                        expandedRowKeys={expandrows}
                        onExpand={onExpand}
                        showSorterTooltip={false}
                    />
                </Row>
            )}
        </React.Fragment>
    );
}
