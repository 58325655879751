import React, { useState, Fragment, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  // ReactReader,
  // EpubViewStyle,
  // ReactReaderStyle,
  EpubView,
} from "react-reader";

import {
  Button,
  Row,
  Drawer,
  List,
  Typography,
  Col,
  Spin,
  message,
  Input,
} from "antd";

import {
  CaretLeftOutlined,
  CaretRightOutlined,
  SettingOutlined,
  CloseOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import moment from "moment";
import Highlighter from "react-highlight-words";

import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";


// import { useLocalStorage } from "../Hooks";

// const { TabPane } = Tabs;

const { Search } = Input;

const App = (props) => {
  const readerRef = useRef();
  const history = useHistory();
  //   const [book, setBook] = useLocalStorage("book", props.fileref? props.fileref: book);
  // const [fullscreen, setFullscreen] = useState(false);
  const [loc, setLoc] = useState(props.bookLoc);
  //   const [rendition, setRendidtion] = useState(null);
  const [largetext, setLargetext] = useState(false);
  // const [mode, setMode] = useState("default");
  const [tocVisible, setTocVisible] = useState(false);
  const [settingVisible, setSettingVisible] = useState(false);
  const [toc, setToc] = useState([]);
  // const [mdata, setMdata] = useState({});
  const [saveloading, setSaveloading] = useState(false);
  const [itemKey, setItemKey] = useState(props.itemKey);
  const [options, setOptions] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [selections, setSelections] = useState([]);
  // const renditionRef = useRef(null);

  // useEffect(() => {

  //   console.log('in useEffect');

  //   if (rendition.current) {
  //     function setRenderSelection(cfiRange, contents) {
  //       setSelections(selections.concat({
  //         text: rendition.current.getRange(cfiRange).toString(),
  //         cfiRange
  //       }))
  //       rendition.current.annotations.add("highlight", cfiRange, {}, null, "hl", { "fill": "red", "fill-opacity": "0.5", "mix-blend-mode": "multiply" })
  //       contents.window.getSelection().removeAllRanges()
  //     }
  //     rendition.current.on("selected", setRenderSelection)
  //     return () => {
  //       rendition.current.off("selected", setRenderSelection)
  //     }
  //   }
  // }, [setSelections, selections])

  // const onMouseUp = e => {
  //   e.preventDefault();

  //   if (rendition.current) {


  //     function setRenderSelection(cfiRange, contents) {
  //       setSelections(selections.concat({
  //         text: rendition.current.getRange(cfiRange).toString(),
  //         cfiRange
  //       }))
  //       rendition.current.annotations.add("highlight", cfiRange, {}, null, "hl", { "fill": "red", "fill-opacity": "0.5", "mix-blend-mode": "multiply" })
  //       contents.window.getSelection().removeAllRanges()
  //     }
  //     rendition.current.on("selected", setRenderSelection)
  //     return () => {
  //       rendition.current.off("selected", setRenderSelection)
  //     }
  //   }
  // };



  // useEffect(() => {
  //   // document.addEventListener("mousemove", onMouseMove);
  //   window.addEventListener("mouseup", onMouseUp);

  //   return () => {
  //     // document.removeEventListener("mousemove", onMouseMove);
  //     window.removeEventListener("mouseup", onMouseUp);
  //   };
  // });


  let rendition = useRef(null);
  const book = rendition.current && rendition.current.book;

  // console.log(book.spine.spineItems);

  //   console.log(rendition);

  //     this.state = {
  //         fullscreen: false,
  //         location:
  //             storage && storage.getItem("epub-location")
  //                 ? storage.getItem("epub-location")
  //                 : 2,
  //         localFile: null,
  //         localName: null,
  //         largeText: false
  //     };
  //     this.rendition = null;
  // }

  const onSearchInputChange = (e) => {
    e.preventDefault();
    setSearchVal(e.target.value);
  };

  // const toggleFullscreen = () => {
  //   // this.setState(
  //   //     {
  //   //         fullscreen: !this.state.fullscreen
  //   //     },
  //   //     () => {
  //   //         setTimeout(() => {
  //   //             const evt = document.createEvent("UIEvents");
  //   //             evt.initUIEvent("resize", true, false, global, 0);
  //   //         }, 1000);
  //   //     }
  //   // );
  //   setFullscreen(!fullscreen);
  // };

  const locationChanged = (lo) => {
    setLoc(lo);
    props.setLoc(lo);
  };

  // const onToggleFontSize = () => {
  //   setLargetext(!largetext);
  // };

  const increaseFontSize = () => {
    setLargetext(true);
    rendition.current.themes.fontSize("140% !important");
    console.log(rendition.current);
  };

  const decreaseFontSize = () => {
    rendition.current.themes.fontSize("100% !important");
    console.log(rendition.current);
  };

  const tocChanged = (v) => {
    setToc(v);
  };

  const next = () => {
    // const node = readerRef.current;
    // node.nextPage();
    rendition.current && rendition.current.next();
    // var currentLocation = book.getCurrentLocationCfi();
    // var currentPage = book.pagination.pageFromCfi(currentLocation);
  };

  const prev = () => {
    // const node = readerRef.current;
    // node.prevPage();
    rendition.current && rendition.current.prev();
  };

  const searchBook = (q) => {
    book &&
      Promise.all(
        book.spine.spineItems.map((item) =>
          item
            .load(book.load.bind(book))
            .then(item.find.bind(item, q))
            .finally(item.unload.bind(item))
        )
      ).then((results) => {
        const flatres = [].concat.apply([], results);
        console.log(flatres);
        setSearchResults(flatres);
        Promise.resolve(flatres);
      });
  };

  // const searchChapter = (q) => {
  //   const book = rendition.current && rendition.current.book;
  //   let item = book.spine.get(rendition.current.location.start.cfi);
  //   return item
  //     .load(book.load.bind(book))
  //     .then(item.find.bind(item, q))
  //     .finally(item.unload.bind(item));
  // };

  const onBookSearch = (q) => {
    searchBook(q);
    // console.log(bookresults);
    // setProjectearchtext(query);
    // const results = projidx && projidx.search(query);
    // // console.log(results);
    // // const rownums = results.map(r => Number.parseInt(r.ref, 10));
    // // here rownums are not necessarily numbers, can be string, depending on id
    // const rownums = results.map((r) => r.ref);
    // setExpandrows(rownums);
    // const showdata = props.data.filter((p) => rownums.includes(p.id));
    // setShown(showdata);
  };

  const getRendition = (rend) => {
    // Set inital font-size, and add a pointer to rendition for later updates

    rendition.current = rend;

    rendition.current.themes.fontSize(largetext ? "140%" : "100%");

    // console.log(rendition.current);

    rendition.current.themes.register({
      dark: "dark.css",
      light: "light.css",
      tan: "tan.css",
    });

    // // rend.themes.select("light");
    rendition.current.themes.select("dark");
    rendition.current.themes.default({
      p: { "font-size": "normal !important" },
    });
    // rendition.current.themes.fontSize("180% !important");

    // setRendidtion(rend);
    setSelections([]);
  };

  // const handleChangeFile = (event, results) => {
  //   if (results.length > 0) {
  //     const [e, file] = results[0];
  //     if (file.type !== "application/epub+zip") {
  //       return alert("Unsupported type");
  //     }
  //     // this.setState({
  //     //     localFile: e.target.result,
  //     //     localName: file.name,
  //     //     location: null
  //     // });
  //   }
  // };

  const onExit = (e) => {
    e.preventDefault();
    localStorage.removeItem("location");
    const submitTime = Math.round(moment().format("X"));

    const submitObj = {
      readLoc: loc,
      lastreadTime: submitTime,
    };

    setSaveloading(true);

    console.log("the itemKey is:", itemKey);

    //   this.setState({ loading: true, error: null });

    const dbref = props.firebase.db.ref(
      `${props.dbrefname}/${props.authUser.uid}/${itemKey}`
    );

    dbref
      .update(submitObj)
      .then(() => {
        setSaveloading(false);
        message.success("Book progress saved");
        history.push(ROUTES.BOOKSHELF);
      })
      .catch((error) => {
        setSaveloading(false);
        console.log("update error", error);
        message.error(error.message, 2);
      });
  };

  return (
    <Fragment>
      <Drawer
        title={null}
        placement="left"
        closable={false}
        onClose={() => setTocVisible(false)}
        visible={tocVisible}
      >
        <List
          className="toc_list"
          //   header="Table of Contents"
          // footer={<div>Footer</div>}
          // bordered
          dataSource={toc}
          renderItem={(item) =>
            item && (
              <List.Item>
                <Link
                  to="#"
                  onClick={() => setLoc(item.href)}
                  style={{ width: "100%", border: "none" }}
                >
                  <Typography.Text>{item && item.label}</Typography.Text>
                </Link>
              </List.Item>
            )
          }
        />
      </Drawer>

      <Drawer
        title={null}
        placement="right"
        closable={false}
        onClose={() => setSettingVisible(false)}
        visible={settingVisible}
      >
        <Button onClick={increaseFontSize}>Increase</Button>
        <Button onClick={decreaseFontSize}>Decrease</Button>
        <Search
          placeholder="search this book"
          onSearch={onBookSearch}
          enterButton
          style={{ width: 300, margin: "auto" }}
          allowClear
          value={searchVal}
          onChange={onSearchInputChange}
        />
        <List
          className="toc_list"
          //   header="Table of Contents"
          // footer={<div>Footer</div>}
          // bordered
          dataSource={searchResults}
          renderItem={(item) =>
            item && (
              <List.Item>
                <Link
                  to="#"
                  onClick={() => setLoc(item.cfi)}
                  style={{ width: "100%", border: "none" }}
                >
                  <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchVal]}
                    autoEscape
                    textToHighlight={
                      item && item.excerpt
                        ? item && item.excerpt.toString()
                        : ""
                    }
                  />
                </Link>
              </List.Item>
            )
          }
        />
      </Drawer>

      <Row
        // type="flex"
        justify="center"
        style={{
          marginTop: 10,
          marginBottom: 10,
          height: "780px",
          //   top: 0,
          //   bottom: 0,
          //   position: "absolute",
        }}
      >
        <Col
          span={1}
          style={{ margin: "auto", height: "100%" }}
          className="pageTurnerCol"
        >
          <Button onClick={() => setTocVisible(true)}>
            <UnorderedListOutlined />
          </Button>
          <Button
            style={{
              width: "60px",
              border: "none",
              height: "100%",
              color: "grey",
            }}
            icon={<CaretLeftOutlined style={{ fontSize: "64px" }} />}
            onClick={prev}
            className="pageTurnButton"
          />
        </Col>

        <Col span={22}>
          {props.fileUrl && !props.fileref === null ? (
            <Spin />
          ) : (
            <EpubView
              ref={readerRef}
              url={props.fileref}
              location={loc}
              locationChanged={locationChanged}
              tocChanged={tocChanged}
              getRendition={getRendition}
              epubOptions={options}
            />
          )}
        </Col>
        <Col
          span={1}
          style={{ margin: "auto", height: "100%" }}
          className="pageTurnerCol"
        >
          <Button onClick={() => setSettingVisible(true)}>
            <SettingOutlined />
          </Button>
          <Button
            style={{
              width: "60px",
              border: "none",
              height: "95%",
              color: "grey",
            }}
            icon={<CaretRightOutlined style={{ fontSize: "64px" }} />}
            onClick={next}
            className="pageTurnButton"
          />
          {/* <CaretRightOutlined
            onClick={next}
            style={{ fontSize: "64px", color: "transparent" }}
            className="pageTurnButton"
          /> */}
          <Button
            onClick={onExit}
            loading={saveloading}
            shape="circle"
            size="large"
            className="pageTurnButton"
            style={{ float: "right" }}
          >
            <CloseOutlined />
          </Button>
        </Col>
      </Row>



      {/* <div style={{ position: 'absolute', bottom: '1rem', right: '1rem', zIndex: 1 }}>
        Selection:
        <ul>
          {selections.map(({ text, cfiRange }, i) => (
            <li key={i}>
              {text} <Button type="primary" onClick={() => {
                rendition.current.display(cfiRange);
                console.log(cfiRange);
              }}>Show</Button>
              <Button onClick={() => {
                rendition.current.annotations.remove(cfiRange, 'highlight')
                setSelections(selections.filter((item, j) => j !== i))
              }}>x</Button>
            </li>
          ))}
        </ul>
      </div> */}



    </Fragment>
  );
};

export default App;
