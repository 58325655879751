// import React, { Component, useState, useEffect } from 'react';
// import { compose } from 'recompose';
// import {
//   Tooltip, Icon, Row, Col, Button, message, Card, Avatar, Switch
// } from 'antd';

// import { withFirebase } from '../Firebase';
// import {
//   AuthUserContext,
//   withAuthorization,
//   withEmailVerification,
// } from '../Session';
import LoginCredPage from "./logincred";
// import ExtendMembershipPage from './extendmembership';
import ProfilePage from "./profile";
import SettingsPage from "./settings";

// const { Meta } = Card;

// const ProfilePage = () =>  <Card
//   title="Default size card"
//   extra={<a href="#">More</a>}
//   style={{ width: 300 }}
// >
//   <p>Card content</p>
//   <p>Card content</p>
//   <p>Card content</p>
// </Card>;

export default ProfilePage;
export { LoginCredPage, SettingsPage };
