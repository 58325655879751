import React from 'react';

import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';


import Page from './page';

function AppBase(props) {

    return (
        <React.Fragment>


            <div style={{ marginTop: 20 }}>


                <Page {...props} dbrefname='userbookshelf'/>

            </div>


        </React.Fragment>
    )
}


const ExtendedAppBase = compose(
    withRouter,
    withFirebase,
)(AppBase);



const App = (props) => {

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <ExtendedAppBase authUser={authUser} />
            )}
        </AuthUserContext.Consumer>

    );
}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(App);

