import React from "react";
import { compose } from "recompose";
import {
  Tooltip,
  Avatar,
  Progress,
  Tabs,
  Skeleton,
  Row,
  Tag,
} from "antd";

import {

  QuestionOutlined,
  TagsOutlined,
} from "@ant-design/icons";

import { withFirebase } from "../Firebase";
// import PasswordChangeForm from '../PasswordChange';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
// import { convertUnixTime } from "../Designs";
import moment from "moment";
import { useFirebase } from "../Hooks";
import { referral_num_needed } from "../../constants/thresh";
import {
  colormappingtf,
  colormapping,
  attris,
} from "../../constants/autocomplete";


const TabPane = Tabs.TabPane;

const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

const save2list = arrayToObject(attris, "savelabel");

// const UserInfoTable = ({
//     firebase,
//     rootname,
//     uid
// }) => {

//     return (
//         <React.Fragment>
//             <p>Password login&nbsp;</p>
//             {/* <p> <Switch
//                 checked={isEnabled}
//                 onClick={isEnabled ? () => onUnlink(signInMethod.id) : pwEdit}
//                 disabled={onlyOneLeft && isEnabled}
//             // onChange={() => message("I see the checked status has changed!")}
//             /></p>
//             <Button type="primary" onClick={pwEdit}>Edit My Password</Button> */}

//         </React.Fragment>
//     );

// }

const PrefTags = (props) =>
  (props.termObject &&
    Object.entries(props.termObject)
      .filter((i) => i[1] === props.tf)
      .map((i) => (
        <Tag
          key={i[0]}
          style={{ marginTop: 6 }}
          color={colormappingtf[props.tf]}
        >
          {i[0]}
        </Tag>
      ))) || <div></div>;

const TermTags = (props) =>
  props.termObject &&
  Object.entries(props.termObject).map((i) => (
    <Tag key={i[0]} style={{ marginTop: 6 }} color={colormapping[i[1]]}>
      <Tooltip title={i[1]}>
        {(save2list[i[0]] && save2list[i[0]].displaylabel) || i[0]}
      </Tooltip>
    </Tag>
  ));

const ProfileCoreBase = (props) => {
  const { data, loading } = useFirebase(
    props.firebase,
    props.rootname,
    props.authUser.uid
  );

  // const basic = useFirebase(props.firebase, "userbasics", props.authUser.uid);

  const avoids = useFirebase(props.firebase, "useravoids", props.authUser.uid);

  const pref = useFirebase(props.firebase, "userprefs", props.authUser.uid);

  console.log(pref.data);

  // const dataSource = [{
  //     key: '1',
  //     name: created,
  //     age: data.created,
  //   }, {
  //     key: '2',
  //     name: 'John',
  //     age: 42,
  //     address: '10 Downing Street'
  //   }];

  //   const columns = [{
  //     title: 'Created',
  //     dataIndex: 'created',
  //     key: 'created',
  //   }, {
  //     title: 'Age',
  //     dataIndex: 'age',
  //     key: 'age',
  //   }, {
  //     title: 'Address',
  //     dataIndex: 'address',
  //     key: 'address',
  //   }];

  //   <Table dataSource={dataSource} columns={columns} />

  // return name;

  return (
    <div
      style={{
        maxWidth: 800,
        margin: "auto",
        paddingLeft: 24,
        paddingRight: 24,
      }}
    >
      <Tabs type="card">
        <TabPane
          tab={
            !loading && (
              <span>
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />{" "}
                &nbsp;{data.username}
              </span>
            )
          }
          key="1"
        >
          <Skeleton avatar active loading={loading}>
            <Row type="flex" justify="end" style={{ marginTop: 40 }}>
              <p>
                Profile completeness&nbsp;
                <Tooltip
                  title="This is a measure on
                            how much we know about your style. The more information we have, 
                            the better our recommendations would be. To improve, add more style tags, or review more items."
                >
                  <QuestionOutlined />
                </Tooltip>
              </p>
              <Progress
                type="circle"
                percent={props.authUser.quiz ? 30 : 5}
                width={80}
                style={{ marginTop: 20, float: "right" }}
              />
            </Row>
            <div style={{ minHeight: 300 }}>
              <p> Member since {moment(data.created).format("YYYY-MM-DD")}</p>
              <p>
                {" "}
                Membership Status:{" "}
                {data.expiry > Date.now() ? "Active" : "Inactive"}
              </p>

              <p>
                {" "}
                Expiry of Active Membership:{" "}
                {moment(data.expiry).format("YYYY-MM-DD")}
              </p>
              <p>
                {" "}
                Number of friends referred: {data.referral_count || 0}{" "}
                &nbsp;&nbsp;&nbsp;{" "}
                {referral_num_needed -
                  ((data.referral_count || 0) % referral_num_needed)}{" "}
                to go!
              </p>
              {/* <p> Bug: when user hasn't filled in the questionnnaire. Location would be empty. Lives in {basic.data.city}, {basic.data.country}</p>  */}
            </div>
          </Skeleton>
        </TabPane>

        <TabPane
          tab={
            !loading && (
              <span>
                <TagsOutlined />
                &nbsp;Style Tags
              </span>
            )
          }
          key="2"
        >
          <Skeleton avatar active loading={pref.loading}>
            <div style={{ marginTop: 40 }}>
              <h4>Styles I like</h4>
              <PrefTags termObject={pref.data} tf={true} />
            </div>
            <div style={{ marginTop: 20 }}>
              <h4>Styles I dislike</h4>
              <PrefTags termObject={pref.data} tf={false} />
            </div>
            <div style={{ marginTop: 20 }}>
              <h4>Specific Attributes I Avoid</h4>
              <TermTags termObject={avoids.data} />
            </div>
          </Skeleton>
        </TabPane>
      </Tabs>
    </div>
  );
};

const ProfileCore = withFirebase(ProfileCoreBase);

// const ProfilePage = () => (
//     <AuthUserContext.Consumer>
//         {authUser => (
//             <ProfileCore uid={authUser.uid} rootname="users" />
//         )}
//     </AuthUserContext.Consumer>
// );

const ProfilePage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <ProfileCore authUser={authUser} rootname="users" />
      // <span></span>
    )}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(ProfilePage);
