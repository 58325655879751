import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import elasticlunr from "elasticlunr";
import DOMPurify from 'dompurify';

import { Button, Table, Input, Row, Skeleton, Space, Switch } from "antd";

import {
  SearchOutlined,
  EditTwoTone,
  DeleteTwoTone,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import Moment from "react-moment";
import * as ROUTES from '../../constants/routes';

const { Search } = Input;

export default function DataTable(props) {
  const [projectsearchtext, setProjectearchtext] = useState("");
  const [searchText, setSearchText] = useState("");
  const [inputVal, setInputVal] = useState("");
  // const [searchedColumn, setSearchedColumn] = useState('');

  const [shown, setShown] = useState([]);
  // "columnFilters" records what kind of column filters are currently applied
  const [columnFilters, setColumnFilters] = useState({});
  const [expandrows, setExpandrows] = useState([]);

  const bookEl = useRef(null);
  const bookclearEl = useRef(null);
  const refSearchBox = useRef(null);



  const handleEdit = (value, row) => {

    // console.log(row);
    props.history.push(ROUTES.TEXTCOMPARE, { url: row.fileUrl, title: row.title });



  }



  // const filteredColumns = ['tags'];

  // const fi = Object.assign(...filteredColumns.map(
  //     name => {
  //         // const vals = (projectData && projectData.map(rd => rd[name])) || [];
  //         const objvals = (props.data && props.data.map(rd => Object.keys(rd[name]))) || [];
  //         const vals = objvals.flat();
  //         const uniqueVals = Array.from(new Set(vals));
  //         return {
  //             [name]: uniqueVals.map(val => ({
  //                 // text: val.toString().charAt(0).toUpperCase() + val.toString().slice(1),
  //                 text: val && val.toString(),
  //                 value: val
  //             }))
  //         }
  //     }
  // ));

  // fil is for each column fileter, what value options there would be to filter

  const projidx = elasticlunr(function () {
    this.addField("title");
    this.addField("description");
    this.addField("creator");
    this.setRef("id");

    props.data.forEach((p) => {
      this.addDoc(p);
    });
  });

  const renderTitle = (query, text, row) => (
    <Link
      to={{
        pathname: `/read`,
        // search: `?book=${row.fileUrl}`,
        state: {
          fileUrl: row.fileUrl,
          itemKey: row.id,
          bookLoc: row.readLoc || 0,
        },
      }}
    >
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[query, projectsearchtext]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    </Link>
  );

  // const renderTitle = (query, text, row) => (

  //         <Highlighter
  //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
  //             searchWords={[query, projectsearchtext]}
  //             autoEscape
  //             textToHighlight={text? text.toString(): ''}
  //         />

  // );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={refs[dataIndex]}
          ref={bookEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            ref={bookclearEl}
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      // <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.select());
        // setTimeout(() => refs[dataIndex].current.select(), 50);
        setTimeout(() => bookEl.current.select(), 50);
      } else {
        // console.log(bookEl.current);
        bookEl.current.input.value = "";
      }
    },
    render: (text, record) => renderTitle(searchText, text, record),
    // (
    //     <Fragment>
    //         <Highlighter
    //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //             searchWords={[searchText, projectsearchtext]}
    //             autoEscape
    //             textToHighlight={text.toString()}
    //         />
    //         {renderTitle(searchText, text, record)}
    //     </Fragment>
    //     // #ff01bb
    // ),
  });

  const clearAllFilters = async () => {
    bookclearEl.current && bookclearEl.current.click();
    setColumnFilters({});
    setProjectearchtext("");
    setExpandrows([]);
    setInputVal("");
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      },
      sortDirections: ["descend", "ascend"],
      filteredValue: columnFilters.title || null,
      ...getColumnSearchProps("title"),
    },
    {
      title: "Creator",
      className: "creator",
      dataIndex: "creator",
      width: 200,
      filteredValue: columnFilters.creator || null,
      ...getColumnSearchProps("creator"),
    },
    {
      title: "Publisher",
      dataIndex: "publisher",
      width: 150,
    },
    // {
    //     title: 'Type',
    //     dataIndex: 'tags',
    //     width: 100,
    //     // sorter: (a, b) => { return a.year - b.year },
    //     // sortDirections: ['descend', 'ascend'],
    //     filters: fi['tags'],
    //     filteredValue: columnFilters.tags || null,
    //     onFilter: (value, record) => Object.keys(record.tags).includes(value),
    //     render: (value, record) => Object.entries(value).map(([k, v], i) => <Tag color={colormapping[v]} key={k} >{k}</Tag>)
    // },
    {
      title: "Last Read",
      className: "column-time",
      dataIndex: "lastreadTime",
      width: 150,
      defaultSortOrder: props.justUploaded ? null : "descend",
      sorter: (a, b, sortOrder) => {
        // always sort the null ones to the bottom
        if (a.lastreadTime != null && b.lastreadTime != null) {
          return a.lastreadTime - b.lastreadTime;
        }
        if (a.lastreadTime) {
          return sortOrder === "ascend" ? -1 : 1;
        }
        if (b.lastreadTime) {
          return sortOrder === "ascend" ? 1 : -1;
        }
        return 0;
      },
      sortDirections: ["descend", "ascend"],
      render: (value, record) =>
        value ? (
          <Moment interval={30000} fromNow>
            {value * 1000}
          </Moment>
        ) : (
          <span></span>
        ),
    },
    {
      title: "Date Added",
      className: "column-time",
      dataIndex: "submitTime",
      width: 150,
      defaultSortOrder: props.justUploaded ? "descend" : null,
      sorter: (a, b) => {
        return a.submitTime - b.submitTime;
      },
      sortDirections: ["descend", "ascend"],
      render: (value, record) => (
        <Moment interval={30000} fromNow>
          {value * 1000}
        </Moment>
      ),
    },
    {
      title: (
        <p>
          Always confirm deletion?{" "}
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={false}
          />
        </p>
      ),
      dataIndex: "id",
      key: "interaction-buttons",
      render: (value, row) => (
        <Space size="large">
          <EditTwoTone
            style={{ fontSize: 20 }}
            // loading={loading.includes(value)}
            onClick={() => handleEdit(value, row)}
          />
          {/* <Popconfirm
                    placement="top"
                    title="Delete this book?"
                    onConfirm={() => props.handleDelete(value)}
                    okText="Yes"
                    cancelText="No"
                > */}
          {/* <Button
                type="primary"
                loading={loading.includes(value)}
                // onClick={() => props.handleDelete(value)}
                >
                Delete
            </Button> */}
          <DeleteTwoTone
            style={{ fontSize: 20 }}
            onClick={() => props.handleDelete(value, row.fileUrl)}
          // loading={loading.includes(value)}
          // onClick={() => props.handleDelete(value)}
          />


          {/* </Popconfirm> */}
        </Space>
      ),
    },
  ];

  const handleChange = (pagination, filters, sorter, extra) => {
    console.log("params: ", pagination, filters, sorter, extra);
    setColumnFilters(filters);
  };

  const onSearchInputChange = (e) => {
    e.preventDefault();
    setInputVal(e.target.value);
  };

  const onAllcolSearch = async (query) => {
    setProjectearchtext(query);
    const results = projidx && projidx.search(query);
    // console.log(results);
    // const rownums = results.map(r => Number.parseInt(r.ref, 10));
    // here rownums are not necessarily numbers, can be string, depending on id
    const rownums = results.map((r) => r.ref);
    setExpandrows(rownums);
    const showdata = props.data.filter((p) => rownums.includes(p.id));
    setShown(showdata);
  };

  const onExpand = (expanded, record) => {
    expanded
      ? setExpandrows([...expandrows, record.id])
      : setExpandrows(expandrows.filter((e) => e !== record.id));
  };

  return (
    <React.Fragment>
      <Row
        type="flex"
        justify="center"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <Search
          ref={refSearchBox}
          placeholder="search among your books"
          onSearch={onAllcolSearch}
          enterButton
          style={{ width: 400, margin: "auto" }}
          allowClear
          value={inputVal}
          onChange={onSearchInputChange}
        />
      </Row>

      <Row
        type="flex"
        justify="start"
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        {/* check if array contains any non-falsy values, use arr.some(el => !!el) --  */}
        <Button
          type="primary"
          onClick={clearAllFilters}
          className={
            Object.values(columnFilters).every((el) => !el) &&
              projectsearchtext === ""
              ? "invisible-button"
              : ""
          }
        >
          Clear filters
        </Button>
      </Row>

      {props.loading ? (
        <Row
          type="flex"
          justify="center"
          style={{ marginTop: 40, marginBottom: 40 }}
        >
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 4, width: ["100%", "100%", "100%", "100%"] }}
          />
        </Row>
      ) : (
        <Row
          type="flex"
          justify="center"
          style={{ marginTop: 40, marginBottom: 40 }}
        >
          <Table
            locale={{ emptyText: <h1> No books yet </h1> }}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={projectsearchtext === "" ? props.data : shown}
            // pagination={{ pageSize: 10 }}
            pagination={false}
            // scroll={{ y: 640 }}
            // expandedRowRender={record => {
            //   const newhtml = ReactDOMServer.renderToStaticMarkup(<Highlighter
            //     highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            //     searchWords={[projectsearchtext]}
            //     autoEscape
            //     textToHighlight={DOMPurify.sanitize(record.description)}
            //   />);
            //   console.log(newhtml);
            //   return (<div dangerouslySetInnerHTML={{ __html: newhtml }}>
            //   </div>)
            // }}
            expandedRowRender={record => <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record.description) }}>
            </div>}
            title={() => 'Sort and filter; click "+" to see description'}
            onChange={handleChange}
            expandedRowKeys={expandrows}
            onExpand={onExpand}
            showSorterTooltip={false}
          />
        </Row>
      )}
    </React.Fragment>
  );
}
