import React, { Component } from "react";
import { compose } from "recompose";
import { Row, Col, Button, message, Switch, Tabs } from "antd";

import {
  LockOutlined,

} from "@ant-design/icons";

import { withFirebase } from "../Firebase";
import PasswordChangeForm from "../PasswordChange";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
// import { Elements, StripeProvider } from 'react-stripe-elements';

const modalList = ["pwchange"];
const TabPane = Tabs.TabPane;

const SIGN_IN_METHODS = [
  {
    id: "password",
    provider: null,
    icon: "lock",
  },
  {
    id: "google.com",
    provider: "googleProvider",
    icon: "google",
  },
  {
    id: "facebook.com",
    provider: "facebookProvider",
    icon: "facebook",
  },
  {
    id: "twitter.com",
    provider: "twitterProvider",
    icon: "twitter",
  },
];

class SettingsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null,
      pwchangeVisible: false,
    };
  }

  showModal = (key) => {
    const obj = Object.assign(
      ...modalList.map((i) => ({ [`${i}Visible`]: i === key }))
    );

    this.setState(obj);

    console.log("showing the modal");
  };

  cancelModal = (key) => {
    // console.log(e);
    console.log("cancelling modal...");

    const stateName = `${key}Visible`;

    this.setState({
      [stateName]: false,
    });
  };

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then((activeSignInMethods) =>
        this.setState({ activeSignInMethods, error: null })
      )
      .catch((error) => {
        this.setState({ error });
        console.log("error fetching signin", error);
        message.error(error.message, 2);
      });
  };

  onSocialLoginLink = async (provider) => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch((error) => {
        this.setState({ error });
        console.log("error linking social login", error);
        message.error(error.message, 2);
      });
  };

  onUnlink = (providerId) => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .then(message.success("Removed login method"))
      .catch((error) => {
        this.setState({ error });
        console.log("error on unlinking", error);
        message.error(error.message, 2);
      });
  };

  onSubmit = () => {
    this.setState({
      activeSignInMethods: [...this.state.activeSignInMethods, "password"],
    });
  };

  render() {
    const { activeSignInMethods } = this.state;

    return (
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
          paddingLeft: 24,
          paddingRight: 24,
          marginTop: 20,
        }}
      >
        <Tabs type="card">
          <TabPane
            tab={
              <span>
                <LockOutlined />
                Login Credentials
              </span>
            }
            key="1"
          >
            <PasswordChangeForm
              visible={this.state.pwchangeVisible}
              handleCancel={() => this.cancelModal("pwchange")}
              onSubmit={this.onSubmit}
            />

            <div style={{ minHeight: 300, marginTop: 40 }}>
              <Row type="flex" justify="center" gutter={0}>
                <h3>Manage Your Login Credentials</h3>
              </Row>
              <div style={{ marginTop: 30 }}>
                <Col offset={2}>
                  <p>Choose among the following login methods.</p>
                  <p>
                    You can switch on/off your password signin method and social
                    signin methods.
                  </p>
                  <p>
                    Account:&nbsp; <b>{this.props.authUser.email}</b>
                  </p>
                </Col>
              </div>

              <div style={{ marginTop: 30 }}>
                <Row type="flex" justify="center" gutter={0}>
                  {SIGN_IN_METHODS.map((signInMethod) => {
                    const onlyOneLeft = activeSignInMethods.length === 1;
                    const isEnabled = activeSignInMethods.includes(
                      signInMethod.id
                    );

                    return signInMethod.id === "password" ? (
                      <Col span={4} offset={2} key={signInMethod.id}>
                        <DefaultLoginToggle
                          onlyOneLeft={onlyOneLeft}
                          isEnabled={isEnabled}
                          signInMethod={signInMethod}
                          // onLink={this.onDefaultLoginLink}
                          onUnlink={this.onUnlink}
                          pwEdit={() => this.showModal("pwchange")}
                        />
                      </Col>
                    ) : (
                      <Col span={4} offset={2} key={signInMethod.id}>
                        <SocialLoginToggle
                          onlyOneLeft={onlyOneLeft}
                          isEnabled={isEnabled}
                          signInMethod={signInMethod}
                          onLink={this.onSocialLoginLink}
                          onUnlink={this.onUnlink}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) => {
  console.log(signInMethod.icon);

  return (
    <React.Fragment>
      {/* <Icon type="google"/> */}

      <p>
        <LockOutlined />
        &nbsp;login&nbsp;
      </p>
      <Switch
        checked={isEnabled}
        onClick={
          isEnabled
            ? () => onUnlink(signInMethod.id)
            : () => onLink(signInMethod.provider)
        }
        disabled={onlyOneLeft && isEnabled}
      // onChange={() => message.success(isEnabled? `${signInMethod.id.replace(".com", "")} login removed`: `${signInMethod.id.replace(".com", "")} login added`)}
      />
    </React.Fragment>
  );
};

const DefaultLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onUnlink,
  pwEdit,
}) => {
  return (
    <React.Fragment>
      <p>Password login&nbsp;</p>
      <p>
        {" "}
        <Switch
          checked={isEnabled}
          onClick={isEnabled ? () => onUnlink(signInMethod.id) : pwEdit}
          disabled={onlyOneLeft && isEnabled}
        // onChange={() => message("I see the checked status has changed!")}
        />
      </p>
      <div style={{ marginTop: 20 }}>
        <Button type="primary" onClick={pwEdit}>
          Edit My Password
        </Button>
      </div>
    </React.Fragment>
  );
};

const Settings = withFirebase(SettingsBase);

const SettingsPage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => <Settings authUser={authUser} />}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(SettingsPage);
