import React, { Component, Fragment } from 'react';

import {
    Row
} from 'antd';


import { makeCancelable } from '../Hooks';

// import ListItem from './list-item';

import Table from './table';

// import vlinks from './hard-video-links.json';


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            // visible: false,
            // dbLoading: false,
            // pushkey: null,
            tableData: [],
            filters: (this.props.match.params.filters && this.props.match.params.filters.split('?')[0]) || "",
            // selectedKey: null,
        };

        this.maxShortnameLength = 18;

        this.maxNameLength = 100;

        this.formRef = React.createRef();

    }

    pendingPromises = [];

    componentWillUnmount = () =>
        this.pendingPromises.map(p => p.cancel());

    appendPendingPromise = promise =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = promise =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);


    // saveLinks = (obj) => {

    //     const dbref = this.props.firebase.db.ref(`userpianovideos/${this.props.authUser.uid}`);


    //     dbref.push(obj)
    //         .catch(error => {
    //             console.log('update error', error);
    //             message.error(error.message, 2);
    //         }).then((snap) => {
    //             // setLoading(false);
    //             // props.onFinish({ [props.pushkey]: submitObj });

    //             const links = obj.links;

    //             if (links) {

    //                 links.map(l => dbref.child(snap.key).child('videos').push(l).catch(error => {
    //                     console.log('update error', error);
    //                     message.error(error.message, 2);
    //                 }));

    //             }
    //             message.success("link saved");
    //         });



    // }


    componentDidMount = () => {

        // console.log(vlinks);

        // vlinks.map(v => this.saveLinks(v));

        const query = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}`);

        const wrappedPromise = makeCancelable(query.once("value"));
        this.appendPendingPromise(wrappedPromise);
        this.setState({ loading: true });

        wrappedPromise.promise
            .then(snapshot => snapshot.val())
            .then(rawdata => {
                if (Object.keys(rawdata).length > 0) {

                    // Object.entries(rawdata).forEach(([k, t], i) => {
                    //     if (!t.name && !t.shortname) {
                    //         this.handleDelete(k);
                    //     }
                    // });

                    const tableData = Object.entries(rawdata).map(([k, t], i) => ({
                        key: k,
                        order: i,
                        name: t.name.split('-').join(' '),
                        source: t.source,
                        videos: t.videos && Object.entries(t.videos).map(([k, v], i) => ({
                            id: k,
                            link: v,
                            order: i
                        })),
                        level: t.level,
                        rating: t.rating || null,
                        submitTime: t.submitTime || null,
                        composer: t.composer || '',
                    }));

                    this.setState({ tableData, loading: false, });
                    this.removePendingPromise(wrappedPromise);

                }
            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error, loading: false });
                    this.removePendingPromise(wrappedPromise);
                }
            });

    }


    handleSave = (key, newdata) => {

        const oldItemIdx = this.state.tableData.findIndex(d => d.key === key);

        this.setState((state, props) => {
            const newItem = { ...state.tableData[oldItemIdx], ...newdata };

            return {
                tableData: [...state.tableData.slice(0, oldItemIdx), newItem, ...state.tableData.slice(oldItemIdx + 1)],
            };
        });

    }



    // updateFilters = (newFilters) => {

    //     this.setState({ filters: newFilters });

    // }




    render() {

        const {
            loading,
            tableData,
            filters
        } = this.state;

        const filterSplits = filters && filters.split("&");
        const filterObj0 = (filterSplits && filterSplits.map(f => f.split('=')).reduce((obj, entry, index) => ({ ...obj, [entry[0]]: entry[1].split(",") }), {})) || {};
        const filterObj = filterObj0.rating ? { ...filterObj0, rating: filterObj0.rating.map(r => parseInt(r)) } : filterObj0;

        return (

            <Fragment>

                <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                    <h1>Piano Videos</h1>
                </Row>



                <Table
                    {...this.props}
                    // refname="usergoals"
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    // dbLoading={dbLoading}
                    data={tableData}
                    // data={[]}
                    loading={loading}
                    handleSave={this.handleSave}
                    filterObj={filterObj}
                    updateFilters={this.updateFilters}
                />



            </Fragment >

        );
    }
}




export default App;

