import React, { Component, Fragment } from "react";

import Abcjs from "react-abcjs";
import ABCJS from "abcjs";

import {
  Button,
  Tooltip,
  message,

  Row,
} from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import { makeCancelable } from "../Hooks";
// import QuestionModal from "./plato-modal";

import { attris } from "../../constants/autocomplete";

import raw from "./temp.abc";

const maxlabellength = 25;

const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

const save2list = arrayToObject(attris, "savelabel");

class UserWritingBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: [],
      error: null,
      iniloading: true,
      loading: false,
      termObject: {},
      visible: false,
      dbLoading: false,
      modalTitle: "",
      modalType: "",
      pushkey: null,
      data: {},
      tableData: [],
      createTime: null,
      ampm: "am",
      timenow: moment(),
      text: "",
      midi: null,
      visualObj: null,
      audioContext: null,
    };

    this.audioContext = null;
  }

  pendingPromises = [];

  initialChildName = "title";

  //   audioContext;

  componentWillUnmount = () => this.pendingPromises.map((p) => p.cancel());

  appendPendingPromise = (promise) =>
    (this.pendingPromises = [...this.pendingPromises, promise]);

  removePendingPromise = (promise) =>
    (this.pendingPromises = this.pendingPromises.filter((p) => p !== promise));

  onPlay = () => {
    const midiBuffer = new ABCJS.synth.CreateSynth();

    const { visualObj } = this.state;

    const ac = this.audioContext;

    console.log(ac);

    this.audioContext.resume().then(function () {
      midiBuffer
        .init({
          visualObj: visualObj,
          audioContext: ac,
          millisecondsPerMeasure:
            (visualObj && visualObj.millisecondsPerMeasure()) || 1500,
        })
        .then(function (response) {
          // console.log(response); // this contains the list of notes that were loaded.
          // midiBuffer.prime actually builds the output buffer.
          return midiBuffer.prime();
        })
        .then(function () {
          // At this point, everything slow has happened. midiBuffer.start will return very quickly and will start playing very quickly without lag.
          midiBuffer.start();
          return Promise.resolve();
        })
        .catch(function (error) {
          if (error.status === "NotSupported") {
            // stopAudioButton.setAttribute("style", "display:none;");
            // var audioError = document.querySelector(".audio-error");
            // audioError.setAttribute("style", "");
            message.error("not supported", 5);
          } else console.warn("synth error", error);
        });
    });
  };

  componentDidMount() {
    fetch(raw)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ text });
        // console.log("text decoded:", text);
        // const synth = new ABCJS.synth.CreateSynth();
        // console.log(abcjs.renderMidi("midi-id", text, {}));

        // let midiBuffer = new ABCJS.synth.CreateSynth();

        const visualObj = ABCJS.renderAbc("*", text, {
          responsive: "resize",
        })[0];

        this.setState({ visualObj }, () => {

        });

        if (ABCJS.synth.supportsAudio()) {
          message.success("supports!", 5);

          window.AudioContext =
            window.AudioContext ||
            window.webkitAudioContext ||
            navigator.mozAudioContext ||
            navigator.msAudioContext;

          this.audioContext = new window.AudioContext();

          //   const synthControl = new ABCJS.synth.SynthController();
          //   synthControl.load("#audio", cursorControl, {
          //     displayLoop: true,
          //     displayRestart: true,
          //     displayPlay: true,
          //     displayProgress: true,
          //     displayWarp: true,
          //   });
        } else {
          message.error("not suppported");
        }

        // abcjs.midi.startPlaying(this.state.midi);

        // abcjs.midi.stopPlaying();

        // abcjs.midi.restartPlaying();

        // abcjs.midi.setLoop(document.querySelector(".abcjs-inline-midi"), true);

        // abcjs.midi.setRandomProgress(0.33);
      });
  }

  onDismissTerm = (savelabel) => {
    const { uid, refname } = this.props;
    const { termObject } = this.state;

    let newto = { ...termObject }; //clone object
    delete newto[savelabel];

    this.setState({ termObject: newto });
    this.removeFromDb(uid, savelabel, refname);
  };

  onSelect = (savelabel) => {
    // we should operate with savelabel, not displaylabel

    if (savelabel.length > maxlabellength) {
      message.error("Description too long", 1);
    } else {
      this.setState((state, props) => ({
        searchTerm: [...state.searchTerm, savelabel],
      }));
    }
  };

  onDeselect = (savelabel) => {
    // we should operate with savelabel, not displaylabel

    this.setState({
      searchTerm: this.state.searchTerm.filter((t) => t !== savelabel),
      // termHistory,
    });
  };

  removeFromDb(uid, label, refname) {
    const ref = this.props.firebase[refname];
    return ref(uid)
      .child(label)
      .remove()
      .catch((error) => {
        this.setState({ error });
      });
  }

  onClick = () => {
    const { searchTerm, termObject } = this.state;
    const { uid, refname } = this.props;
    const ref = this.props.firebase[refname];
    let termsObj;
    const cleanterms = [...new Set(searchTerm.map((i) => i.trim()))];

    if (searchTerm.length < 1) {
      return;
    }

    // console.log('termHistory:', termHistory);
    termsObj = Object.assign(
      ...cleanterms.map((i) => ({
        [i]: (save2list[i] && save2list[i].aspect) || "other",
      }))
    );

    this.setState({
      searchTerm: [],
      termObject: { ...termObject, ...termsObj },
      visible: false,
      dbLoading: false,
    });

    ref(uid)
      .update(termsObj)
      .catch((error) => {
        this.setState({ error });
        console.log(error);
      });
  };

  showModal = (title) => {
    this.setState({
      modalTitle: title,
      visible: true,
    });
  };

  handleCancel = (itemkey, del) => {
    this.setState({
      visible: false,
    });
    if (del) {
      this.handleDelete(itemkey);
    }
  };

  handleSave = (id, newdata) => {
    const oldItem =
      this.state.tableData.filter((d) => d.id === id).length > 0
        ? this.state.tableData.filter((d) => d.id === id)[0]
        : {};
    this.setState((state, props) => {
      const newItem =
        state.modalType === "create"
          ? {
            ...oldItem,
            ...newdata,
            createTime: state.createTime,
            // createTimeCal: moment(state.createTime * 1000).format('MMMM Do YYYY, h:mm:ss a'),
            // createTimeText: moment(state.createTime * 1000).fromNow(),
          }
          : { ...oldItem, ...newdata };

      return {
        visible: false,
        tableData: [newItem, ...state.tableData.filter((d) => d.id !== id)],
      };
    });
  };

  handleCreate = () => {
    this.showModal("Create Your Morning Routine");
  };

  handleEdit = (itemkey) => {
    this.setState({
      dbLoading: true,
      modalType: "edit",
      pushkey: itemkey,
      visible: true,
    });
  };

  handleDelete = (itemkey) => {
    const dbref = this.props.firebase.db.ref(
      `${this.props.refname}/${this.props.authUser.uid}/${itemkey}`
    );
    // const query = ref(this.props.uid);
    const wrappedPromise = makeCancelable(dbref.remove());
    this.appendPendingPromise(wrappedPromise);
    this.setState({
      dbLoading: true,
      tableData: this.state.tableData.filter((d) => d.id !== itemkey),
    });

    wrappedPromise.promise
      .then(() => this.removePendingPromise(wrappedPromise))
      .catch((errorInfo) => {
        if (!errorInfo.isCanceled) {
          this.setState({ error: errorInfo.error });
          this.removePendingPromise(wrappedPromise);
        } else {
          console.log("book deletion is cancelled");
        }
      });
    this.setState({ dbLoading: false });
  };

  render() {
    const {
      text,
    } = this.state;

    // const termTags =
    //   termObject &&
    //   Object.entries(termObject).map((i) => (
    //     <Tag
    //       key={i[0]}
    //       style={{ marginTop: 6 }}
    //       closable
    //       afterClose={() => this.onDismissTerm(i[0])}
    //       color={colormapping[i[1]]}
    //     >
    //       <Tooltip title={i[1]}>
    //         {(save2list[i[0]] && save2list[i[0]].displaylabel) || i[0]}
    //       </Tooltip>
    //     </Tag>
    //   ));

    // const passthresh = (h) => {
    //   return timenow.hour() >= h ? true : false;
    // };

    const ampm_exp =
      "You can view and edit your morning routines of the day before 4pm, and evening routines after 4pm.";

    return (
      <Fragment>
        {/* <Input defaultValue='try this'></Input> */}
        <Row type="flex" justify="center" style={{ marginTop: 40 }}>
          <h1>Music is music to my ears</h1>&nbsp;
          <Tooltip title={ampm_exp}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>

        <Row type="flex" justify="center" style={{ marginTop: 40 }}>
          <Button onClick={this.onPlay}>Play</Button>
        </Row>

        <div
          style={{
            width: "100%",
            margin: "auto",
            marginTop: 30,
            backgroundColor: "white",
          }}
        >
          <Abcjs
            abcNotation={text}
            parserParams={{}}
            engraverParams={{ responsive: "resize" }}
            renderParams={{ viewportHorizontal: true }}
          />
        </div>
      </Fragment>
    );
  }
}

export default UserWritingBase;
